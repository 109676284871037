import { createContext, useContext, useEffect, useState } from 'react';
import CreateDataContext from './CreateDataProvider';
import axios from 'axios';
import socket from '../socket_connection/socket';
import constant from '../constant';
import { v4 as uuidv4 } from 'uuid';
import { toast } from 'react-toastify';

const FacebookContext = createContext({


    // Functions
    getAllFacebookPageEvents: () => { },
    getFacebookPageConversations: () => { },
    getFacebookPagePostInfo: () => { },
    getWebHookNotificationInfo: () => { },
    getFacebokPageMessages: () => { },
    sendMessageToUserFacebookMessenger: () => { },
    addCommentToFacebookPost: () => { },
    deleteFacebookPostComment: () => { },
    addFacebookCommentReply: () => { },
    getUsersTaggedPost: () => { },
})


export const FacebookContextProvider = (props) => {
    const createDataContext = useContext(CreateDataContext);
    // eslint-disable-next-line
    const [refreshPage, setRefreshPage] = useState(false)

    // NOTE: FEED CODE

    // NOTE: Find parent comment of the reply
    const findParentComment = (messages, id) => {
        for (const message of messages) {
            if (message.id === id) {
                return message;
            }
            if (message.replies) {
                for (const reply of message.replies) {
                    if (reply.id === id) {
                        return message;
                    }
                }
            }
        }
        return null; // If no matching comment is found
    }

    // NOTE: Check if the comment or reply is made from creatosaurus inbox if yes then don't show the webhook notification because the comment is already in system
    const isIdPresent = (dataArray, targetId) => {
        for (const item of dataArray) {
            if (item.id === targetId) {
                return true; // Found in the main array
            }

            if (item.replies && item.replies.length > 0) {
                // Check in replies array
                if (isIdPresent(item.replies, targetId)) {
                    return true; // Found in replies
                }
            }
        }

        return false; // ID not found
    };


    // NOTE: Socket receiver for webhook notification
    useEffect(() => {
        // NOTE: receiving facebook page feed notification
        socket.on('facebookpage-feed', handleNotifications)
        socket.on('facebookpage-message', handleNotifications)
        return () => {
            socket.off('facebookpage-feed', handleNotifications)
            socket.off('facebookpage-message', handleNotifications)
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    // NOTE: Handle the facebook page webhook feed & messenger notification
    const handleNotifications = async (data) => {
        try {
            constant.eventData = data
            setRefreshPage((prev) => !prev)
            if (constant.eventData) {
                const activeAccountId = constant.activeAccount.socialId
                if (constant.activeNotificationType === "feed" && constant.eventData.type === "feed") {
                    const postId = constant.eventData.postid_or_userid
                    const pageId = postId.match(/(\d+)_/);
                    const accountId = pageId[1];
                    if (activeAccountId === accountId) {
                        await getWebhookNotificationInfo(constant.eventData)
                    }
                } else if (constant.activeNotificationType === "messages" && constant.eventData.type === "messenger") {
                    const webhookNotificationAccountId = constant.eventData.message === undefined ? constant.eventData.to.id : constant.eventData.message.to.id
                    if (activeAccountId === webhookNotificationAccountId) {
                        await getMessengerWebhookNotificationInfo(constant.eventData)
                    }
                }
            }
        } catch (error) {
            console.error('Error while making API request:', error);
        }
    }

    const convertUnixTimestampToISO = (unixTimestamp) => {
        const date = new Date(unixTimestamp * 1000);

        const year = date.getUTCFullYear();
        const month = String(date.getUTCMonth() + 1).padStart(2, '0');
        const day = String(date.getUTCDate()).padStart(2, '0');
        const hours = String(date.getUTCHours()).padStart(2, '0');
        const minutes = String(date.getUTCMinutes()).padStart(2, '0');
        const seconds = String(date.getUTCSeconds()).padStart(2, '0');

        const isoString = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}+0000`;

        return isoString;
    }


    // NOTE: Get webhook notification info such as comment by the notification
    const getWebhookNotificationInfo = async (data) => {
        try {
            const workspaceId = localStorage.getItem('organizationId')
            if (workspaceId === data.workspaceId) {
                const webhookNotificationId = data.postid_or_userid
                const isConversationExists = constant.conversationList.find((data) => data.postid_or_userid === webhookNotificationId)
                const accountId = constant.activeAccount.socialId
                const accountToken = constant.activeAccount.accessToken
                if (isConversationExists === undefined) {
                    createDataContext.handleWebhookNotificationLoading(true)
                    const event = data
                    socket.emit('create', `${accountId}_${event.postid_or_userid}`)
                    if (!event.newMessage) {
                        setTimeout(() => {
                            createDataContext.handleWebhookNotificationLoading(false)
                            if (constant.conversationList.length === 0) {
                                createDataContext.handleActiveChat(event)
                            }
                            createDataContext.handleConversationList([event, ...constant.conversationList])
                            createDataContext.handleAllConversations([event, ...constant.conversationList])
                        }, 1000)
                    } else {
                        const postResponse = await axios.get(`https://graph.facebook.com/v17.0/${event.postid_or_userid}?fields=full_picture,attachments,message,created_time,likes.summary(true).limit(0),comments.summary(true).limit(0)&access_token=${accountToken}`);
                        let pagePost = {
                            name_or_caption: postResponse.data.message === undefined ? "No Caption" : postResponse.data.message,
                            postid_or_userid: postResponse.data.id,
                            likes: postResponse.data.likes.summary.total_count,
                            comments: postResponse.data.comments.summary.total_count,
                            media_url: postResponse.data.full_picture,
                            created_time: postResponse.data.created_time,
                            attachments: postResponse.data.attachments === undefined ? [] : postResponse.data.attachments.data[0]?.subattachments?.data.map((data) => ({
                                media_type: data.type === "photo" ? "image" : "video",
                                media_url: data.media.image.source,
                                id: data.target.id
                            })) || postResponse.data.attachments.data.map((data) => ({
                                media_type: data.media.type === "photo" ? "image" : "video",
                                media_url: data.media.image.source,
                                id: data.target.id
                            })),
                            conversationType: 'feed',
                            type: 'feed',
                            accountType: 'facebookpage',
                            unread: 1,
                            workspaceId: workspaceId,
                            isPostExists: true
                        }
                        setTimeout(() => {
                            createDataContext.handleWebhookNotificationLoading(false)
                            if (constant.conversationList.length === 0) {
                                createDataContext.handleActiveChat(pagePost)
                            }
                            createDataContext.handleConversationList([pagePost, ...constant.conversationList])
                            createDataContext.handleAllConversations([pagePost, ...constant.conversationList])
                        }, 1000)
                    }

                } else {
                    // NOTE: this code is for the feed which is active or open
                    if (constant.activeChat?.postid_or_userid === webhookNotificationId) {
                        const commentParentId = data.notification.value.parent_id
                        const commentId = data.notification.value.comment_id
                        const isCommentMadeFromInbox = isIdPresent(constant.messages, commentId)
                        //   NOTE: not showing the webhook notification if the comment is made from inbox
                        if (!isCommentMadeFromInbox) {
                            createDataContext.handleNewMessageCount(constant.newMessagesCount + 1)
                            // NOTE: checking if it is a new comment or reply
                            if (commentParentId === webhookNotificationId) {
                                createDataContext.handleCommentLoading(true)
                                const picture = await getFbUsersProfilePic(data.notification.value.from.id, accountToken)
                                let newNotification = {
                                    comment_count: 0,
                                    like_count: 0,
                                    created_time: convertUnixTimestampToISO(data.notification.value.created_time),
                                    from: {
                                        ...data.notification.value.from,
                                        picture: {
                                            data: {
                                                url: picture
                                            }
                                        }
                                    },
                                    id: data.notification.value.comment_id,
                                }
                                if (data.notification.value.message !== undefined) {
                                    newNotification.message = data.notification.value.message
                                }
                                if (data.notification.value.photo !== undefined) {
                                    newNotification.media = [{
                                        url: data.notification.value.photo,
                                        type: "image",
                                        id: uuidv4()
                                    }]
                                }
                                const latestComment = [newNotification, ...constant.messages]
                                const commentList = createDataContext.createCommentTree(latestComment)
                                createDataContext.handleMessages(commentList)
                                createDataContext.handleCommentLoading(false)
                                createDataContext.handleActiveChat({
                                    ...constant.activeChat,
                                    comments: constant.activeChat.comments + 1
                                })
                                const accountId = constant.activeAccount?.socialId
                                const response = await axios.put(`${constant.socialInboxUrl}inbox/event/update/unread/${workspaceId}/${constant.activeChat?.postid_or_userid}/${accountId}/facebookpage/feed`)
                                if (response.status === 200) {
                                    const updatedConversation = constant.conversationList.map(conversation => {
                                        return conversation.postid_or_userid === constant.activeChat?.postid_or_userid
                                            ? { ...conversation, unread: 0 }
                                            : conversation;
                                    });

                                    createDataContext.handleConversationList(updatedConversation);
                                }
                            } else {
                                const parent = findParentComment(constant.messages, commentParentId)
                                createDataContext.handleViewReplies({
                                    id: parent.id,
                                    isActive: true
                                })
                                const parentIndex = constant.messages.findIndex((data) => data.id === parent.id);
                                const picture = await getFbUsersProfilePic(data.notification.value.from.id, accountToken)
                                let newNotification = {
                                    comment_count: 0,
                                    like_count: 0,
                                    created_time: convertUnixTimestampToISO(data.notification.value.created_time),
                                    from: {
                                        ...data.notification.value.from,
                                        picture: {
                                            data: {
                                                url: picture
                                            }
                                        }
                                    },
                                    id: data.notification.value.comment_id,
                                }
                                if (data.notification.value.message !== undefined) {
                                    newNotification.message = data.notification.value.message
                                }
                                if (data.notification.value.photo !== undefined) {
                                    newNotification.media = [{
                                        url: data.notification.value.photo,
                                        type: "image",
                                        id: uuidv4()
                                    }]
                                }
                                if (parent.replies === undefined) {
                                    parent.comment_count = parent.comment_count + 1
                                    parent.replies = [newNotification]
                                } else {
                                    parent.comment_count = parent.comment_count + 1
                                    parent.replies.unshift(newNotification);
                                }
                                let updatedArray = [...constant.messages];
                                updatedArray[parentIndex] = parent
                                const newComment = createDataContext.createCommentTree(updatedArray)
                                createDataContext.handleMessages(newComment)
                                createDataContext.handleActiveChat({
                                    ...constant.activeChat,
                                    comments: constant.activeChat.comments + 1
                                })
                                const accountId = constant.activeAccount?.socialId
                                const response = await axios.put(`${constant.socialInboxUrl}inbox/event/update/unread/${workspaceId}/${webhookNotificationId}/${accountId}/facebookpage/feed`)
                                if (response.status === 200) {
                                    const updatedConversation = constant.conversationList.map(conversation => {
                                        return conversation.postid_or_userid === constant.activeChat?.postid_or_userid
                                            ? { ...conversation, unread: 0 }
                                            : conversation;
                                    });

                                    createDataContext.handleConversationList(updatedConversation);
                                }
                            }
                        }
                    } else {
                        // NOTE: this code is for chat or feed which is not active
                        const updatedList = constant.conversationList.map((feed) => {
                            if (feed.postid_or_userid === webhookNotificationId) {
                                return {
                                    ...feed,
                                    latest_message: `New Comment: ${data.notification.value.message}`,
                                    unread: feed.unread + 1,
                                };
                            }
                            return feed;
                        });

                        const updatedListWithMovedItem = [
                            updatedList.find((feed) => feed.postid_or_userid === webhookNotificationId),
                            ...updatedList.filter((feed) => feed.postid_or_userid !== webhookNotificationId)
                        ];

                        createDataContext.handleConversationList(updatedListWithMovedItem);
                    }
                }
            }
        } catch (error) {
            console.log(error)
        }
    }


    // NOTE: Get Facebook page posts events
    const getAllFacebookPageEvents = async (loadMore) => {
        try {
            if (loadMore) {
                createDataContext.handleLoadMore(true)
                createDataContext.handleChatLoading(false)
                createDataContext.handleConversationLoading(false);
            } else {
                createDataContext.handleConversationLoading(true);
                createDataContext.handlePaginationInfo(null)
            }

            const accountId = createDataContext.activeAccount?.socialId;
            const accountToken = createDataContext.activeAccount?.accessToken;
            const workspaceId = localStorage.getItem('organizationId');


            if (constant.cancelToken) {
                constant.cancelToken.cancel(); // Cancel the previous request before making a new request
            }

            // Create a new CancelToken
            constant.cancelToken = axios.CancelToken.source();

            // Fetch event IDs from the socialInbox API
            const eventResponse = await axios.get(`${constant.socialInboxUrl}inbox/event/${workspaceId}/${accountId}/facebookpage/feed?page=${constant.paginationInfo?.page || 1}&limit=${constant.paginationInfo?.limit || 10}`, {
                cancelToken: constant.cancelToken.token
            });
            const eventIds = eventResponse.data.events.map(data => data.eventId);

            // Fetch page posts in parallel using Promise.all
            const pagePostsPromises = eventIds.map(async (postId) => {
                try {
                    const response = await axios.get(`https://graph.facebook.com/v17.0/${postId}?fields=full_picture,attachments,message,created_time,likes.summary(true).limit(0),comments.summary(true).limit(0)&access_token=${accountToken}`, {
                        cancelToken: constant.cancelToken.token
                    });
                    return response.data;
                } catch (error) {
                    // Handle the error for the current request
                    console.error(`Error fetching data for postId ${postId}:`, error.message);
                    // You can return a placeholder or null for this failed request, or handle it as needed
                    return null;
                }
            });

            // Wait for all promises to complete
            const pagePostsData = await Promise.all(pagePostsPromises);

            // Filter out the results that are null (requests that failed)
            const pagePosts = pagePostsData.filter(data => data !== null);

            const updatedPagePosts = pagePosts.map((post, index) => {
                const registeredPost = eventResponse.data.events.find((data) => data.eventId === post.id);
                if (!registeredPost) return null; // Return early if registeredPost is not found
                let attachments = [];
                if (post.attachments !== undefined) {
                    attachments = post.attachments.data[0]?.subattachments?.data || post.attachments.data;
                }

                return {
                    name_or_caption: post.message || "No Caption",
                    postid_or_userid: post.id,
                    likes: post.likes.summary.total_count,
                    comments: post.comments.summary.total_count,
                    media_url: post.full_picture,
                    created_time: post.created_time,
                    attachments: attachments.length > 0 ? attachments.map((data) => ({
                        media_type: data.type === "photo" ? "image" : "video",
                        media_url: data.media.image.src,
                        id: data.target.id
                    })) : [],
                    conversationType: 'feed',
                    accountType: 'facebookpage',
                    unread: index === 0 ? 0 : registeredPost.unread,
                    isPostExists: true
                };
            }).filter(Boolean); // Remove null values from the resulting array
            if (eventIds.length === 0) {
                createDataContext.handleConversationList([])
                createDataContext.handleAllConversations([])
            } else {
                if (updatedPagePosts[0].unread > 0) {
                    const response = await axios.put(`${constant.socialInboxUrl}inbox/event/update/unread/${workspaceId}/${updatedPagePosts[0].postid_or_userid}/${accountId}/facebookpage/feed`)
                    if (response.status === 200) {
                        let obj = {
                            ...updatedPagePosts[0],
                            unread: 0
                        }
                        createDataContext.handleActiveChat(obj);
                    }
                } else {
                    if (!loadMore) {
                        createDataContext.handleActiveChat(updatedPagePosts[0]);
                    }
                }
            }

            createDataContext.handleConversationList([...constant.conversationList, ...updatedPagePosts]);
            createDataContext.handleAllConversations([...constant.allConversationList, ...updatedPagePosts]);
            createDataContext.handlePaginationInfo({
                ...eventResponse.data.pagination,
                page: Number(eventResponse.data.pagination.page) + 1
            })
            eventIds.forEach((data) => {
                socket.emit('create', data);
            });
            createDataContext.handleLoadMore(false)
            createDataContext.handleConversationLoading(false);
            createDataContext.handleChatLoading(false)
        } catch (error) {
            createDataContext.handleConversationLoading(false);
            createDataContext.handleChatLoading(false)
            console.error(error);
        }
    };


    const getFacebookPagePostInfo = async (loadMore) => {
        try {
            createDataContext.handleMoreOptions(false);
            if (loadMore) {
                createDataContext.handleLoadMoreComments(true)
            } else {
                createDataContext.handleChatLoading(true)
            }

            const { accessToken } = createDataContext.activeAccount || {};
            const postId = createDataContext.activeChat?.postid_or_userid;


            if (constant.cancelToken) {
                constant.cancelToken.cancel(); // Cancel the previous request before making a new request
            }

            // Create a new CancelToken
            constant.cancelToken = axios.CancelToken.source();

            const response = await axios.get(`https://graph.facebook.com/v17.0/${postId}/comments`, {
                params: {
                    fields: "from{id,name,picture},id,message,created_time,like_count,comment_count,parent,attachment,object,total_count",
                    filter: "stream",
                    access_token: accessToken,
                    order: "reverse_chronological",
                    after: createDataContext.pageCursor.cursor !== "" && loadMore ? createDataContext.pageCursor.cursor : '',
                    limit: 50
                },
                cancelToken: constant.cancelToken.token
            });

            const commentData = response.data.data;

            if (response.data.data.length === 0) {
                createDataContext.handlePageCursor({
                    isMore: false,
                    cursor: ''
                })
            } else {
                if (response.data.paging !== undefined) {
                    if (response.data.data.length < 50) {
                        createDataContext.handlePageCursor({
                            isMore: false,
                            cursor: ''
                        })
                    } else {
                        createDataContext.handlePageCursor({
                            isMore: true,
                            cursor: response.data.paging.cursors.after
                        })
                    }
                }
            }

            const comments = commentData.map((data) => {
                if (data.attachment !== undefined) {
                    let obj = {
                        ...data,
                        media: [{
                            id: data.attachment.target.id,
                            url: data.attachment.media[data.attachment.type === "photo" || data.attachment.type === "sticker" ? "image" : data.attachment.type === "share" ? "image" : "video"].src,
                            type: data.attachment.type === "photo" || data.attachment.type === "sticker" ? "image" : "video"
                        }]
                    }
                    return obj
                } else {
                    return data
                }
            })

            if (loadMore) {
                const commentList = createDataContext.createCommentTree(comments);
                const moreComments = [...createDataContext.messages, ...commentList]
                if (createDataContext.activeMoreOptionTab === 'Positive Comments' || createDataContext.activeMoreOptionTab === 'Negative Comments') {
                    createDataContext.getSentiments(commentList)
                    if (response.data.paging === undefined) {
                        createDataContext.handlePageCursor({
                            isMore: false,
                            cursor: ''
                        })
                    }
                    createDataContext.handleLoadMoreComments(false)
                } else {
                    createDataContext.handleMessages(moreComments)
                    createDataContext.handleAllMessages(moreComments)
                    if (response.data.paging === undefined) {
                        createDataContext.handlePageCursor({
                            isMore: false,
                            cursor: ''
                        })
                    }
                    createDataContext.handleLoadMoreComments(false)
                }
            } else {
                const commentList = createDataContext.createCommentTree(comments);
                createDataContext.handleAllMessages(commentList)
                createDataContext.handleMessages(commentList);
                if (response.data.paging === undefined) {
                    createDataContext.handlePageCursor({
                        isMore: false,
                        cursor: ''
                    })
                }
                createDataContext.handleChatLoading(false);
            }
        } catch (error) {
            console.error(error);
            createDataContext.handleChatLoading(false);
        } finally {
            createDataContext.handleChatLoading(false);
        }
    };

    // NOTE: Add comment to facebook post
    const addCommentToFacebookPost = async () => {
        try {
            createDataContext.handleSendingMessage(true)
            const accountToken = createDataContext.activeAccount?.accessToken
            const postId = createDataContext.activeChat?.postid_or_userid
            let body = {
                message: createDataContext.text,
                access_token: accountToken
            }

            if (createDataContext.messageMedia.length > 0) {
                body.attachment_url = createDataContext.messageMedia[0].URL
            }

            const response = await axios.post(`https://graph.facebook.com/v17.0/${postId}/comments`, body)

            const res = await axios.get(`https://graph.facebook.com/v17.0/${response.data.id}?fields=from{id,name,picture},id,message,created_time,like_count,comment_count,comments,attachment,object&access_token=${accountToken}`)
            const comment = res.data
            let obj;
            if (comment.attachment !== undefined) {
                obj = {
                    ...comment,
                    media: [{
                        id: comment.attachment.target.id,
                        url: comment.attachment.media[comment.attachment.type === "photo" ? "image" : "video"].src,
                        type: comment.attachment.type === "photo" ? "image" : "video"
                    }]
                }
            } else {
                obj = {
                    ...comment
                }
            }

            const newArray = [obj, ...createDataContext.messages]
            const newComment = createDataContext.createCommentTree(newArray)
            createDataContext.handleMessages(newComment)
            createDataContext.handleText("", "text")
            createDataContext.handleMessageMedia([])
            createDataContext.handleSendingMessage(false)
        } catch (error) {
            toast.error('Oops! Something went wrong. Please try again.');
            createDataContext.handleSendingMessage(false)
            console.log(error)
        }
    }

    // NOTE: Delete facebook page comment
    const deleteFacebookPostComment = async (comment_id) => {
        try {
            createDataContext.handleDeleting({
                isLoading: true,
                id: comment_id
            })
            const accountToken = createDataContext.activeAccount?.accessToken
            await axios.delete(`https://graph.facebook.com/v17.0/${comment_id}?access_token=${accountToken}`)
            const removedComment = createDataContext.recursivelyRemoveComment(createDataContext.messages, comment_id);
            createDataContext.handleMessages(removedComment)
            createDataContext.handleDeleting({
                isLoading: false,
                id: ''
            })
            toast.success("Comment deleted successfully.")
        } catch (error) {
            console.log(error)
            toast.error('Oops! Something went wrong. Please try again.');
            createDataContext.handleDeleting({
                isLoading: false,
                id: ''
            })
        }
    }

    // NOTE: add reply to facebook comment
    const addFacebookCommentReply = async (id) => {
        try {
            createDataContext.handleSendingReply(true)
            const accountToken = createDataContext.activeAccount?.accessToken
            const postId = createDataContext.activeChat?.postid_or_userid
            await axios.post(`https://graph.facebook.com/v17.0/${id}/comments?fields=from,id,message,created_time,like_count,comment_count,comments,parent`, {
                access_token: accountToken,
                message: createDataContext.replyText
            })
            const allCommentResponse = await axios.get(`https://graph.facebook.com/v17.0/${postId}/comments?fields=from{id,name,picture},id,message,created_time,like_count,comment_count,parent&filter=stream&access_token=${accountToken}&order=reverse_chronological`)
            const newReply = createDataContext.createCommentTree(allCommentResponse.data.data)
            createDataContext.handleMessages(newReply)
            createDataContext.handleSendingReply(false)
            createDataContext.handleReplyText("", "text")
            createDataContext.handleReplyTo("")
        } catch (error) {
            console.log(error)
            toast.error('Oops! Something went wrong. Please try again.');
            createDataContext.handleSendingReply(false)
            createDataContext.handleReplyTo("")
            createDataContext.handleReplyText("", "text")
        }
    }


    // NOTE: get facebook user profile pic
    const getFbUsersProfilePic = async (psid, accountToken) => {
        try {
            const response = await axios.get(`https://graph.facebook.com/v17.0/${psid}?fields=profile_pic&access_token=${accountToken}`)
            return response.data.profile_pic
        } catch (error) {
            console.log(error)
        }
    }


    // NOTE: Messenger Webhook notification info
    const getMessengerWebhookNotificationInfo = async (data) => {
        try {
            const workspaceId = localStorage.getItem('organizationId')
            if (workspaceId === data.workspaceId) {
                const isUserExists = constant.conversationList.find((person) => person.conversation_id === data.conversationId)

                if (isUserExists !== undefined) {
                    const conversationId = constant.activeChat.conversation_id
                    if (conversationId === data.conversationId) {
                        createDataContext.handleCommentLoading(true)
                        const updatedMessages = [data.message, ...constant.messages]
                        createDataContext.handleNewMessageCount(constant.newMessagesCount + 1)
                        setTimeout(() => {
                            if (!createDataContext.commentLoading) {
                                createDataContext.handleMessages(updatedMessages)
                            }
                            createDataContext.handleCommentLoading(false)
                        }, 1000)
                        const accountId = constant.activeAccount.socialId
                        const unReadResponse = await axios.put(`${constant.socialInboxUrl}inbox/event/update/unread/${workspaceId}/${constant.activeChat.conversation_id}/${accountId}/facebookpage/messenger`)
                        if (unReadResponse.status === 200) {
                            const updatedConversation = constant.conversationList.map(conversation => {
                                return conversation.conversation_id === constant.activeChat?.conversation_id
                                    ? { ...conversation, unread: 0 }
                                    : conversation;
                            });

                            createDataContext.handleConversationList(updatedConversation);
                        }
                    } else {
                        const updatedList = constant.conversationList.map((person) => {
                            if (person.postid_or_userid === data.message.from.id) {
                                return {
                                    ...person,
                                    latest_message: data.message.message,
                                    unread: person.unread ? person.unread + 1 : 1
                                };
                            }
                            return person;
                        });
                        const updatedListWithMovedItem = [
                            updatedList.find((messenger) => messenger.postid_or_userid === data.message.from.id),
                            ...updatedList.filter((messenger) => messenger.postid_or_userid !== data.message.from.id)
                        ];

                        createDataContext.handleConversationList(updatedListWithMovedItem);
                    }
                } else {
                    createDataContext.handleWebhookNotificationLoading(true)
                    const conversationResponse = await axios.get(`https://graph.facebook.com/v17.0/${data.conversationId}?fields=participants&access_token=${constant.activeAccount?.accessToken}`);
                    const conversation = conversationResponse.data;
                    const profilePic = await getFbUsersProfilePic(conversation.participants.data[0].id, constant.activeAccount?.accessToken);
                    if (data.newMessage) {
                        let event = {
                            name_or_caption: conversation.participants.data[0].name,
                            postid_or_userid: conversation.participants.data[0].id,
                            conversation_id: conversation.id,
                            media_url: profilePic,
                            to: conversation.participants.data[1],
                            conversationType: 'messenger',
                            accountType: "facebookpage"
                        }
                        if (constant.conversationList.length === 0) {
                            createDataContext.handleActiveChat(event)
                        }

                        let updatedConversationList = [event, ...constant.conversationList];
                        createDataContext.handleConversationList(updatedConversationList)
                        createDataContext.handleWebhookNotificationLoading(false)
                    } else {
                        if (constant.conversationList.length === 0) {
                            createDataContext.handleActiveChat(data.message)
                        }

                        let updatedConversationList = [data.message, ...constant.conversationList];
                        createDataContext.handleConversationList(updatedConversationList)
                        createDataContext.handleWebhookNotificationLoading(false)
                    }

                }
            }
        } catch (error) {
            console.log(error)
        }
    }

    // NOTE: get facebook page conversations list
    const getFacebookPageConversations = async (loadMore, isPrivateReply) => {
        try {
            if (loadMore) {
                createDataContext.handleLoadMore(true)
                createDataContext.handleChatLoading(false)
                createDataContext.handleConversationLoading(false);
            } else {
                createDataContext.handleConversationLoading(true);
                createDataContext.handlePaginationInfo(null)
            }
            const accountId = createDataContext.activeAccount?.socialId
            const accountToken = createDataContext.activeAccount?.accessToken
            const workspaceId = localStorage.getItem('organizationId');

            // Fetch event IDs from the socialInbox API
            const eventResponse = await axios.get(`${constant.socialInboxUrl}inbox/event/${workspaceId}/${accountId}/facebookpage/messenger?page=${constant.paginationInfo?.page || 1}&limit=${constant.paginationInfo?.limit || 10}`);
            const eventIds = eventResponse.data.events.map(data => data.eventId);
            // Fetch events details in parallel using Promise.all
            const pageConversationsPromises = eventIds.map(async (conversationId) => {
                const response = await axios.get(`https://graph.facebook.com/v17.0/${conversationId}?fields=participants&access_token=${accountToken}`);
                return response.data;
            });


            const conversations = await Promise.all(pageConversationsPromises);
            const updatedMessengerConversation = await Promise.all(conversations.map(async (conversation, index) => {
                const registeredConversation = eventResponse.data.events.find(data => data.eventId === conversation.id);

                if (!registeredConversation) return null; // Return early if registeredConversation is not found

                try {
                    const [profilePic] = await Promise.all([
                        getFbUsersProfilePic(conversation.participants.data[0].id, accountToken)
                    ]);

                    return {
                        name_or_caption: conversation.participants.data[0].name,
                        postid_or_userid: conversation.participants.data[0].id,
                        conversation_id: conversation.id,
                        media_url: profilePic,
                        conversationType: 'messenger',
                        accountType: "facebookpage",
                        unread: index === 0 ? 0 : registeredConversation.unread,
                        isMessageWindowActive: registeredConversation?.isMessageWindowActive !== undefined ? registeredConversation?.isMessageWindowActive : false
                    };
                } catch (error) {
                    console.error('Error fetching profile picture:', error);
                    return null;
                }
            }));

            // Filter out null values (conversations without registeredConversation)
            const filteredConversations = updatedMessengerConversation.filter(conversation => conversation !== null);


            if (updatedMessengerConversation.length === 0) {
                createDataContext.handleActiveChat({})
                createDataContext.handleConversationList([])
                createDataContext.handleMessages([])
            } else {
                if (filteredConversations[0].unread > 0) {
                    const response = await axios.put(`${constant.socialInboxUrl}inbox/event/update/unread/${workspaceId}/${filteredConversations[0].conversation_id}/${accountId}/facebookpage/messenger`)
                    if (response.status === 200) {
                        let obj = {
                            ...filteredConversations[0],
                            unread: 0
                        }
                        createDataContext.handleActiveChat(obj);
                    }
                } else {
                    if (!loadMore) {
                        if (!isPrivateReply) {
                            createDataContext.handleActiveChat(filteredConversations[0]);
                        }
                    }
                }
            }

            createDataContext.handleConversationList(filteredConversations)
            createDataContext.handleAllConversations(filteredConversations)

            createDataContext.handlePaginationInfo({
                ...eventResponse.data.pagination,
                page: Number(eventResponse.data.pagination.page) + 1
            })

            eventIds.forEach((data) => {
                socket.emit('create', data);
            });
            createDataContext.handleConversationLoading(false)
            createDataContext.handleChatLoading(false)
        } catch (error) {
            createDataContext.handleConversationLoading(false)
            createDataContext.handleChatLoading(false)
            console.log(error)
        }
    }

    // NOTE: Get all messages of facebook page messenger
    const getFacebokPageMessages = async (loadMore) => {
        try {

            createDataContext.handleMoreOptions(false)
            if (loadMore) {
                createDataContext.handleLoadMoreComments(true)
            } else {
                createDataContext.handleChatLoading(true)
            }
            const accountToken = createDataContext.activeAccount?.accessToken
            const conversationId = createDataContext.activeChat?.conversation_id
            if (constant.cancelToken) {
                constant.cancelToken.cancel(); // Cancel the previous request before making a new request
            }

            // Create a new CancelToken
            constant.cancelToken = axios.CancelToken.source();

            if (accountToken !== undefined && conversationId !== undefined) {
                const response = await axios.get(`https://graph.facebook.com/v17.0/${conversationId}/messages`, {
                    params: {
                        fields: 'message,to,from,created_time,attachments,shares{description,link,id,name,template}',
                        access_token: accountToken,
                        after: createDataContext.pageCursor.cursor !== "" && loadMore ? createDataContext.pageCursor.cursor : '',
                        limit: 25
                    },
                    cancelToken: constant.cancelToken.token
                })

                if (response.data.data.length === 0) {
                    createDataContext.handlePageCursor({
                        isMore: false,
                        cursor: ''
                    })
                } else {
                    if (response.data.paging !== undefined) {
                        if (response.data.data.length < 25) {
                            createDataContext.handlePageCursor({
                                isMore: false,
                                cursor: ''
                            })
                        } else {
                            createDataContext.handlePageCursor({
                                isMore: true,
                                cursor: response.data.paging.cursors.after
                            })
                        }
                    }
                }
                if (loadMore) {
                    const messages = [...createDataContext.messages, ...response.data.data]
                    createDataContext.handleMessages(messages)
                    if (response.data.paging === undefined) {
                        createDataContext.handlePageCursor({
                            isMore: false,
                            cursor: ''
                        })
                    }
                    createDataContext.handleLoadMoreComments(false)
                } else {
                    createDataContext.handleMessages(response.data.data)
                    if (response.data.paging === undefined) {
                        createDataContext.handlePageCursor({
                            isMore: false,
                            cursor: ''
                        })
                    }
                    createDataContext.handleChatLoading(false)
                }
            }
        } catch (error) {
            createDataContext.handleChatLoading(false)
            createDataContext.handleLoadMoreComments(false)
            console.log(error)
        }
    }


    // NOTE: update the message to messages array by getting whole message info from facebook
    const updateMessages = async (messageIds) => {
        try {
            const accountToken = createDataContext.activeAccount?.accessToken;
            for await (const messageId of messageIds) {
                const messageInfo = await axios.get(`https://graph.facebook.com/v17.0/${messageId}?fields=id,created_time,from,to,message,attachments&access_token=${accountToken}`);
                const newMessage = [messageInfo.data, ...createDataContext.messages]
                createDataContext.handleMessages(newMessage)
            }
        } catch (error) {
            console.log(error);
        }
    };

    // NOTE: Send message to user using facebook page
    const sendMessageToUserFacebookMessenger = async () => {
        try {
            createDataContext.handleSendingMessage(true)
            const accountToken = createDataContext.activeAccount?.accessToken
            const pageId = createDataContext.activeAccount?.socialId
            const recipient_id = createDataContext.activeChat?.postid_or_userid
            if (createDataContext.messageMedia.length > 0) {
                let messageIdArray = []
                const response = await axios.post(`https://graph.facebook.com/v17.0/${pageId}/messages`, {
                    recipient: { id: recipient_id },
                    messaging_type: "RESPONSE",
                    message: {
                        attachment: {
                            type: createDataContext.messageMedia[0].type,
                            payload: {
                                url: createDataContext.messageMedia[0].URL,
                                is_reusable: true
                            }
                        }
                    },
                    access_token: accountToken,
                    MESSAGE_TAG: 'HUMAN_AGENT'
                })

                messageIdArray.push(response.data.message_id)

                if (createDataContext.text !== "") {
                    const messageWithAttachment = await axios.post(`https://graph.facebook.com/v17.0/${pageId}/messages`, {
                        recipient: { id: recipient_id },
                        messaging_type: "RESPONSE",
                        message: { text: createDataContext.text },
                        access_token: accountToken,
                        MESSAGE_TAG: 'HUMAN_AGENT'
                    })
                    messageIdArray.push(messageWithAttachment.data.message_id)
                }
                await updateMessages(messageIdArray)
            } else {
                const response = await axios.post(`https://graph.facebook.com/v17.0/${pageId}/messages`, {
                    recipient: { id: recipient_id },
                    messaging_type: "RESPONSE",
                    message: { text: createDataContext.text },
                    access_token: accountToken,
                    MESSAGE_TAG: 'HUMAN_AGENT'
                })
                await updateMessages([response.data.message_id])
            }

            if (!createDataContext.activeChat?.isMessageWindowActive) {
                await axios.put(`${constant.socialInboxUrl}inbox/event/update/message/window/${createDataContext.activeChat?.conversation_id}/${createDataContext.activeAccount?.socialId}/facebookpage/messenger`)
                createDataContext.handleActiveChat({
                    ...createDataContext.activeChat,
                    isMessageWindowActive: true
                })
            }

            createDataContext.handleText("", "text")
            createDataContext.handleMessageMedia([])
            createDataContext.handleSendingMessage(false)
        } catch (error) {
            console.log(error)
            toast.error('Oops! Something went wrong while sending the message. Please try again.');
            createDataContext.handleText("", "text")
            createDataContext.handleSendingMessage(false)
        }

    }


    const getUsersTaggedPost = async (loadMore) => {
        try {
            if (loadMore) {
                createDataContext.handleLoadMore(true)
                createDataContext.handleChatLoading(false)
                createDataContext.handleConversationLoading(false);
            } else {
                createDataContext.handleConversationLoading(true);
                createDataContext.handlePaginationInfo(null)
            }

            const accountId = createDataContext.activeAccount?.socialId;
            const accountToken = createDataContext.activeAccount?.accessToken;
            const response = await axios.get(`https://graph.facebook.com/v17.0/${accountId}/tagged?access_token=${accountToken}&fields=full_picture,attachments,message,from,created_time,likes.summary(true).limit(0),comments.summary(true).limit(0)`)
            const updatedTaggedData = response.data.data.map((post, index) => {
                let attachments = [];
                if (post.attachments !== undefined) {
                    attachments = post.attachments.data[0]?.subattachments?.data || post.attachments.data;
                }

                return {
                    name_or_caption: post.message || "No Caption",
                    postid_or_userid: post.id,
                    likes: post.likes.summary.total_count,
                    comments: post.comments.summary.total_count,
                    media_url: post.full_picture,
                    created_time: post.created_time,
                    attachments: attachments.length > 0 ? attachments.map((data) => ({
                        media_type: data.type === "photo" ? "image" : "video",
                        media_url: data.media.image.src,
                        id: data.target.id
                    })) : [],
                    conversationType: 'tags',
                    accountType: 'facebookpage',
                    owner: post.from.name,
                    unread: 0,
                    isPostExists: true
                };
            }).filter(Boolean); // Remove null values from the resulting array
            createDataContext.handleActiveChat(updatedTaggedData[0])
            createDataContext.handleConversationList(updatedTaggedData)
            createDataContext.handleAllConversations(updatedTaggedData)
            createDataContext.handleLoadMore(false)
            createDataContext.handleConversationLoading(false);
            createDataContext.handleChatLoading(false)

        } catch (error) {
            createDataContext.handleConversationLoading(false);
            createDataContext.handleChatLoading(false)
            console.error(error);
        }
    }






    const context = {

        // Functions
        getAllFacebookPageEvents: getAllFacebookPageEvents,
        getFacebookPageConversations: getFacebookPageConversations,
        getFacebookPagePostInfo: getFacebookPagePostInfo,
        getFacebokPageMessages: getFacebokPageMessages,
        sendMessageToUserFacebookMessenger: sendMessageToUserFacebookMessenger,
        addCommentToFacebookPost: addCommentToFacebookPost,
        deleteFacebookPostComment: deleteFacebookPostComment,
        addFacebookCommentReply: addFacebookCommentReply,
        getUsersTaggedPost: getUsersTaggedPost
    }
    return (
        <FacebookContext.Provider value={context}>{props.children}</FacebookContext.Provider>
    )
}



export default FacebookContext