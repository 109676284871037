const constant = {
    socialInboxUrl: 'https://api.inbox.creatosaurus.io/',
    cacheUrl: 'https://api.cache.creatosaurus.io/cache/',
    mainWebURL: 'https://api.app.creatosaurus.io/creatosaurus/',
    stenoURL: "https://m1f8u47vyf.execute-api.ap-south-1.amazonaws.com/latest/steno",
    // cacheUrl: 'http://localhost:4006/cache/',
    // socialInboxUrl: 'http://localhost:4000/',
    //mainWebURL:'http://localhost:4002/creatosaurus/',
    searchToken: null, // important don't remove this line
    eventData: null,
    activeAccount: null,
    conversationList: [],
    allConversationList: [],
    activeChat: null,
    messages: [],
    allMessages: [],
    activeNotificationType: "feed",
    commentsMessagesMadeFromInbox: [],
    paginationInfo: null,
    loadMoreComments: false,
    newMessagesCount: 0,
    cancelToken: undefined,
};

export default constant;