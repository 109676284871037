import React from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
// import ProtectedRoutes from "./screens/ProtectedRoutes"
// import Home from './screens/home/Home'
import NotSupported from './screens/not_supported/NotSupported'
import UnderMaintenance from './maintenance/UnderMaintenance'


const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<UnderMaintenance />} />
        {/* <Route path="/" element={<ProtectedRoutes><Home /></ProtectedRoutes>} /> */}
        <Route path="/safari-not-supported" element={<NotSupported />} />
      </Routes>
    </BrowserRouter>
  )
}

export default App;
