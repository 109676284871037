import { createContext, useState } from 'react';

const ButtonContext = createContext({
    activeRightButton: 'rightApps',
    activeAppStoreApp: "",
    notificationPopUpActive: false,

    changeRightButton: () => { },
    activateAppStoreApp: () => { },
    handleNotificationPopup: () => { }
});

export const ButtonContextProvider = (props) => {
    const [activeRightButton, setactiveRightButton] = useState("rightApps");
    const [activeAppStoreApp, setActiveAppStoreApp] = useState("");
    const [notificationPopUpActive, setNotificationPopUpActive] = useState(false)



    const changeRightButton = (value) => {
        setactiveRightButton(value);
    };

    const activateAppStoreApp = (value) => {
        setActiveAppStoreApp(value);
    };

    const handleNotificationPopup = (value) => {
        setNotificationPopUpActive(value)
    }

    const context = {
        activeRightButton: activeRightButton,
        activeAppStoreApp: activeAppStoreApp,
        notificationPopUpActive: notificationPopUpActive,


        changeRightButton: changeRightButton,
        activateAppStoreApp: activateAppStoreApp,
        handleNotificationPopup: handleNotificationPopup
    };

    return (
        <ButtonContext.Provider value={context}>{props.children}</ButtonContext.Provider>
    );
};

export default ButtonContext;