import { createContext, useState } from 'react';
import constant from '../constant'
import axios from 'axios';
import Sentiment from 'sentiment';

const CreateDataContext = createContext({
    userData: [],
    canEdit: false,
    usersAccounts: [],
    activeAccount: {},
    activeChat: null,
    accountLoading: false,
    conversationLoading: true,
    conversationList: [],
    pagePosts: [],
    messages: [],
    allMessages: [],
    allConversations: [],
    chatLoading: false,
    moreOptions: false,
    messageMedia: [],
    sendingMessage: false,
    text: "",
    deleting: {
        isLoading: false,
        id: ""
    },
    sendingReply: false,
    replyText: "",
    replyingTo: "",
    webhookNotificationLoading: false,
    read: false,
    commentLoading: false,
    activeNotificationType: "feed",
    unread: 0,
    unreadAccountInfo: [],
    accountsToConnect: [],
    accountConnectionLoading: false,
    isAccountConnectPopupActive: false,
    paginationInfo: null,
    loadMore: false,
    loadMoreComments: false,
    pageCursor: {
        isMore: false,
        cursor: ''
    },
    newMessageCount: 0,
    repliesParentText: '',
    aiLoading: false,
    isAiOptionPopupActive: false,
    aiGeneratedText: "",
    mainAiGeneratedText: "",
    viewReplies: {
        id: '',
        isActive: false,
    },
    activeMoreOptionTab: 'All Comments',
    privateReply: null,
    reactionLoading: false,
    selectedComment: null,


    getUserData: () => { },
    getUsersAccount: () => { },
    handleActiveAccount: () => { },
    handleActiveChat: () => { },
    handleConversationLoading: () => { },
    handleConversationList: () => { },
    handlePagePost: () => { },
    handleMessages: () => { },
    handleUsersAccounts: () => { },
    handleAllMessages: () => { },
    handleAllConversations: () => { },
    handleChatLoading: () => { },
    handleMoreOptions: () => { },
    handleMessageMedia: () => { },
    handleSendingMessage: () => { },
    handleDeleting: () => { },
    handleText: () => { },
    removeMedia: () => { },
    handleSendingReply: () => { },
    handleReplyText: () => { },
    handleReplyTo: () => { },
    createCommentTree: () => { },
    recursivelyRemoveComment: () => { },
    handleWebhookNotificationLoading: () => { },
    handleRead: () => { },
    handleCommentLoading: () => { },
    handleActiveNotificationType: () => { },
    getNotifications: () => { },
    handleAccountsToConnect: () => { },
    handleAccountConnectionLoading: () => { },
    handleAccountConnectPopup: () => { },
    updateUsersAccounts: () => { },
    updateUsersDataAccountLimit: () => { },
    handlePaginationInfo: () => { },
    handleLoadMore: () => { },
    handleLoadMoreComments: () => { },
    handlePageCursor: () => { },
    handleNewMessageCount: () => { },
    handleRepliesParentText: () => { },
    handleAiLoading: () => { },
    handleAiOptionPopup: () => { },
    handleAiGeneratedText: () => { },
    handleViewReplies: () => { },
    handleActiveMoreOptionsTabs: () => { },
    getSentiments: () => { },
    handlePrivateReply: () => { },
    handleReactionLoading: () => { },
    handleSelectedComment: () => { },
})



export const CreateDataProvider = (props) => {
    const [userData, setuserData] = useState([])
    const [usersAccounts, setUserAccounts] = useState([])
    const [canEdit, setcanEdit] = useState(false)
    const [activeAccount, setActiveAccount] = useState({})
    const [activeChat, setActiveChat] = useState({})
    const [accountLoading, setAccountLoading] = useState(false)
    const [conversationLoading, setConversationsLoading] = useState(true)
    const [conversationList, setConversationList] = useState([])
    const [messages, setMessages] = useState([])
    const [allMessages, setAllMessages] = useState([])
    const [pagePosts, setPagePosts] = useState([])
    const [allConversations, setAllConversations] = useState([])
    const [chatLoading, setChatLoading] = useState(false)
    const [moreOptions, setMoreOptions] = useState(false)
    const [messageMedia, setMessageMedia] = useState([])
    const [sendingMessage, setSendingMessage] = useState(false)
    const [text, setText] = useState("")
    const [deleting, setDeleting] = useState({
        isLoading: false,
        id: ""
    })
    const [sendingReply, setSendingReply] = useState(false)
    const [replyText, setReplyText] = useState("")
    const [replyingTo, setReplyingTo] = useState("")
    const [repliesParentText, setRepliesParentText] = useState("")
    const [webhookNotificationLoading, setWebhookNotificationLoading] = useState(false)
    const [read, setRead] = useState(false)
    const [commentLoading, setCommentLoading] = useState(false)
    const [activeNotificationType, setActiveNotificationType] = useState("feed")
    const [unread, setUnread] = useState(0)
    const [unreadAccountInfo, setUnreadAccountInfo] = useState([])
    const [accountsToConnect, setAccountsToConnect] = useState([])
    const [accountConnectionLoading, setAccountConnectionLoading] = useState(false)
    const [isAccountConnectPopupActive, setIsAccountConnectPopupActive] = useState(false)
    const [paginationInfo, setPaginationInfo] = useState(null)
    const [loadMore, setLoadMore] = useState(false)
    const [loadMoreComments, setLoadMoreComments] = useState(false)
    const [pageCursor, setPageCursor] = useState({
        isMore: false,
        cursor: ''
    })
    const [newMessageCount, setNewMessageCount] = useState(0)
    // eslint-disable-next-line
    const [refreshPage, setRefreshPage] = useState(false)
    const [aiLoading, setAiLoading] = useState(false)
    const [isAiOptionPopupActive, setIsAiOptionPopupActive] = useState(false)
    const [aiGeneratedText, setAiGeneratedText] = useState("")
    const [mainAiGeneratedText, setMainAiGeneratedText] = useState("")
    const [viewReplies, setViewReplies] = useState({
        id: '',
        isActive: false
    })
    const [activeMoreOptionTab, setActiveMoreOptionTab] = useState('All Comments')
    const [privateReply, setPrivateReply] = useState(null)
    const [reactionLoading, setReactionLoading] = useState(false)
    const [selectedComment, setSelectedComment] = useState(null)


    // get users data
    const getUserData = async () => {
        try {

            const token = localStorage.getItem('token');
            const config = { headers: { Authorization: `Bearer ${token}` } };
            const res = await axios.get(constant.mainWebURL + '/user/info', config);

            // update the localstorage
            localStorage.setItem('organizationId', res.data.activeWorkspace._id);
            localStorage.setItem('organizationName', res.data.activeWorkspace.workspace_name);

            // check user can edit or noe
            let userDetails = res.data.activeWorkspace.team.filter(data => data.user_email === res.data.userData.email)
            if (userDetails[0].role !== "view") {
                setcanEdit(true)
            } else {
                setcanEdit(false)
            }

            // update state
            setuserData(res.data)
            getUsersAccount()
        } catch (error) {
            console.log(error)
        }
    }

    // get users connected accounts from cache
    const getUsersAccount = async () => {
        try {
            setConversationsLoading(true)
            setAccountLoading(true)
            const token = localStorage.getItem('token');
            const workspaceId = localStorage.getItem('organizationId')
            const config = { headers: { Authorization: `Bearer ${token}` } };
            const res = await axios.get(constant.cacheUrl + `accounts/${workspaceId}`, config);
            const filteredAccounts = res.data.filter(data =>
                data.accountType === "linkedinpage" ||
                data.accountType === "instagram" ||
                data.accountType === "facebookpage"
            );
            setUserAccounts(filteredAccounts)
            setActiveAccount(filteredAccounts[0])
            constant.activeAccount = filteredAccounts[0]
            setRefreshPage((prev) => !prev)
            setAccountLoading(false)
            setConversationsLoading(false)
        } catch (error) {
            console.log(error)
            setAccountLoading(false)
            setConversationsLoading(false)
        }
    }

    const updateUsersAccounts = (value) => {
        setUserAccounts(value)
    }

    const updateUsersDataAccountLimit = (value) => {
        let userDataCopy = userData
        userDataCopy.featureFactoryData.socialAccounts = value
        setuserData(userDataCopy)
    }

    const handleAccountsToConnect = (value) => {
        setAccountsToConnect(value)
    }

    const handleActiveAccount = (value) => {
        constant.activeAccount = value
        setActiveAccount(value)
    }

    const handleActiveChat = (value) => {
        setActiveChat(value)
        constant.activeChat = value
        setRefreshPage((prev) => !prev)
    }

    const handleConversationLoading = (value) => {
        setConversationsLoading(value)
    }

    const handleConversationList = (value) => {
        setConversationList(value)
        constant.conversationList = value
        setRefreshPage((prev) => !prev)
    }

    const handlePagePosts = (value) => {
        setPagePosts(value)
    }

    const handleMessages = (value) => {
        setMessages(value)
        constant.messages = value
        setRefreshPage((prev) => !prev)
    }

    const handleUsersAccounts = (value) => {
        setUserAccounts(value)
    }

    const handleAllMessages = (value) => {
        setAllMessages(value)
        constant.allMessages = value
        setRefreshPage((prev) => !prev)
    }

    const handleAllConversations = (value) => {
        setAllConversations(value)
        constant.allConversationList = value
        setRefreshPage((prev) => !prev)
    }

    const handleChatLoading = (value) => {
        setChatLoading(value)
    }

    const handleMoreOptions = (value) => {
        setMoreOptions(value)
    }

    const handleMessageMedia = (value) => {
        setMessageMedia(value)
    }

    const handleSendingMessage = (value) => {
        setSendingMessage(value)
    }

    const handleDeleting = (value) => {
        setDeleting(value)
    }

    const handleText = (value, type) => {
        if (type === "text") {
            setText(value)
        } else {
            setText(text => text + value)
        }
    }

    const removeMedia = (value) => {
        const data = messageMedia.filter((media) => media.id !== value)
        setMessageMedia(data)
    }

    const handleSendingReply = (value) => {
        setSendingReply(value)
    }

    const handleReplyText = (value, type, ai) => {
        if (type === "text") {
            if (ai) {
                setReplyText(prev => prev + value)
            } else {
                setReplyText(value)
            }
        } else {
            setReplyText(replyText => replyText + value)
        }
    }

    const handleReplyTo = (value) => {
        setReplyingTo(value)
    }

    const handleWebhookNotificationLoading = (value) => {
        setWebhookNotificationLoading(value)
    }

    // NOTE: organizing comments according to there parent ids
    const createCommentTree = (commentList) => {
        const commentMap = new Map();
        const rootComments = [];

        for (const comment of commentList) {
            const { parent } = comment;

            if (!parent) {
                rootComments.push(comment);
            } else {
                if (!commentMap.has(parent.id)) {
                    commentMap.set(parent.id, []);
                }
                commentMap.get(parent.id).push(comment);
            }
        }

        const addRepliesToComment = (comment) => {
            const { id } = comment;
            const replies = commentMap.get(id);
            if (replies) {
                comment.replies = replies;
                for (const reply of replies) {
                    addRepliesToComment(reply);
                }
            }
        };

        rootComments.forEach(addRepliesToComment);

        return rootComments;
    };


    // NOTE: Remove comment from array if deleted using api
    const recursivelyRemoveComment = (comments, commentIdToRemove) => {
        return comments.map(comment => {
            if (comment.id === commentIdToRemove) {
                return null; // Remove this comment
            }

            if (comment.replies) {
                comment.replies = recursivelyRemoveComment(comment.replies, commentIdToRemove);
                return comment;
            }

            return comment;
        }).filter(Boolean); // Remove the null entries
    };

    const handleRead = (value) => {
        setRead(value)
    }

    const handleCommentLoading = (value) => {
        setCommentLoading(value)
    }


    const handleActiveNotificationType = (value) => {
        setActiveNotificationType(value)
    }


    const getNotifications = async () => {
        try {
            const workspaceId = localStorage.getItem('organizationId')
            const response = await axios.get(`${constant.socialInboxUrl}inbox/event/notifications/${workspaceId}`)
            const events = response.data.events


            let allTotalUnread = 0;
            const updatedUserAccounts = context.usersAccounts.map((account) => {
                const totalUnread = events.reduce((accumulator, event) => {
                    return event.accountId === account.socialId ? accumulator + event.unread : accumulator;
                }, 0);
                allTotalUnread += totalUnread
                return {
                    ...account,
                    totalUnread: totalUnread,
                };
            });
            setUnread(allTotalUnread);
            setUnreadAccountInfo(updatedUserAccounts)


        } catch (error) {
            console.log(error)
        }
    }


    const handleAccountConnectionLoading = (value) => {
        setAccountConnectionLoading(value)
    }

    const handleAccountConnectPopup = (value) => {
        setIsAccountConnectPopupActive(value)
    }

    const handlePaginationInfo = (value) => {
        constant.paginationInfo = value
        setPaginationInfo(value)
    }

    const handleLoadMore = (value) => {
        setLoadMore(value)
    }

    const handleLoadMoreComments = (value) => {
        setLoadMoreComments(value)
        constant.loadMoreComments = value
        setRefreshPage((prev) => !prev)
    }

    const handlePageCursor = (value) => {
        setPageCursor(value)
    }

    const handleNewMessageCount = (value) => {
        setNewMessageCount(value)
        constant.newMessagesCount = value
        setRefreshPage((prev) => !prev)
    }

    const handleRepliesParentText = (value) => {
        setRepliesParentText(value)
    }

    const handleAiLoading = (value) => {
        setAiLoading(value)
    }


    const handleAiOptionPopup = (value) => {
        setIsAiOptionPopupActive(value)
    }

    const handleAiGeneratedText = (value, type) => {
        if (type === "reply") {
            setAiGeneratedText(value)
        } else {
            setMainAiGeneratedText(value)
        }
    }

    const handleViewReplies = (value) => {
        setViewReplies(value)
    }

    const handleActiveMoreOptionsTabs = (value) => {
        setActiveMoreOptionTab(value)
    }

    const getSentiments = (comments) => {
        const sentiment = new Sentiment();
        let positiveComments = []
        let negativeComments = []

        comments.forEach(comment => {
            const { message } = comment;
            const analysis = sentiment.analyze(message);

            if (analysis.score >= 0) {
                positiveComments.push(comment);
            } else {
                negativeComments.push(comment);
            }
        });

        if (activeMoreOptionTab === 'Positive Comments') {
            setMessages([...messages, ...positiveComments])
        } else {
            setMessages([...messages, ...negativeComments])
        }
    }

    const handlePrivateReply = (value) => {
        setPrivateReply(value)
    }

    const handleReactionLoading = (value) => {
        setReactionLoading(value)
    }

    const handleSelectedComment = (value) => {
        setSelectedComment(value)
    }


    const context = {
        userData: userData,
        canEdit: canEdit,
        usersAccounts: usersAccounts,
        activeAccount: activeAccount,
        activeChat: activeChat,
        accountLoading: accountLoading,
        conversationLoading: conversationLoading,
        conversationList: conversationList,
        messages: messages,
        pagePosts: pagePosts,
        allMessages: allMessages,
        allConversations: allConversations,
        chatLoading: chatLoading,
        moreOptions: moreOptions,
        messageMedia: messageMedia,
        sendingMessage: sendingMessage,
        text: text,
        deleting: deleting,
        sendingReply: sendingReply,
        replyText: replyText,
        replyingTo: replyingTo,
        webhookNotificationLoading: webhookNotificationLoading,
        read: read,
        commentLoading: commentLoading,
        activeNotificationType: activeNotificationType,
        unread: unread,
        unreadAccountInfo: unreadAccountInfo,
        accountsToConnect: accountsToConnect,
        accountConnectionLoading: accountConnectionLoading,
        isAccountConnectPopupActive: isAccountConnectPopupActive,
        paginationInfo: paginationInfo,
        loadMore: loadMore,
        loadMoreComments: loadMoreComments,
        pageCursor: pageCursor,
        newMessageCount: newMessageCount,
        repliesParentText: repliesParentText,
        aiLoading: aiLoading,
        isAiOptionPopupActive: isAiOptionPopupActive,
        aiGeneratedText: aiGeneratedText,
        mainAiGeneratedText: mainAiGeneratedText,
        viewReplies: viewReplies,
        activeMoreOptionTab: activeMoreOptionTab,
        privateReply: privateReply,
        reactionLoading: reactionLoading,
        selectedComment: selectedComment,

        getUserData: getUserData,
        handleActiveAccount: handleActiveAccount,
        handleActiveChat: handleActiveChat,
        handleConversationLoading: handleConversationLoading,
        handleConversationList: handleConversationList,
        handlePagePosts: handlePagePosts,
        handleMessages: handleMessages,
        handleUsersAccounts: handleUsersAccounts,
        getUsersAccount: getUsersAccount,
        handleAllConversations: handleAllConversations,
        handleChatLoading: handleChatLoading,
        handleMoreOptions: handleMoreOptions,
        handleMessageMedia: handleMessageMedia,
        handleSendingMessage: handleSendingMessage,
        handleDeleting: handleDeleting,
        handleText: handleText,
        removeMedia: removeMedia,
        handleSendingReply: handleSendingReply,
        handleReplyText: handleReplyText,
        handleReplyTo: handleReplyTo,
        handleWebhookNotificationLoading: handleWebhookNotificationLoading,
        createCommentTree: createCommentTree,
        recursivelyRemoveComment: recursivelyRemoveComment,
        handleRead: handleRead,
        handleCommentLoading: handleCommentLoading,
        handleActiveNotificationType: handleActiveNotificationType,
        getNotifications: getNotifications,
        handleAccountsToConnect: handleAccountsToConnect,
        handleAccountConnectionLoading: handleAccountConnectionLoading,
        handleAccountConnectPopup: handleAccountConnectPopup,
        updateUsersAccounts: updateUsersAccounts,
        updateUsersDataAccountLimit: updateUsersDataAccountLimit,
        handlePaginationInfo: handlePaginationInfo,
        handleLoadMore: handleLoadMore,
        handleLoadMoreComments: handleLoadMoreComments,
        handlePageCursor: handlePageCursor,
        handleNewMessageCount: handleNewMessageCount,
        handleRepliesParentText: handleRepliesParentText,
        handleAiLoading: handleAiLoading,
        handleAiOptionPopup: handleAiOptionPopup,
        handleAiGeneratedText: handleAiGeneratedText,
        handleViewReplies: handleViewReplies,
        handleAllMessages: handleAllMessages,
        handleActiveMoreOptionsTabs: handleActiveMoreOptionsTabs,
        getSentiments: getSentiments,
        handlePrivateReply: handlePrivateReply,
        handleReactionLoading: handleReactionLoading,
        handleSelectedComment: handleSelectedComment
    }

    return (
        <CreateDataContext.Provider value={context}>{props.children}</CreateDataContext.Provider>
    )
}


export default CreateDataContext