import { createContext, useContext, useEffect, useState } from 'react';
import constant from '../constant';
import axios from 'axios';
import CreateDataContext from './CreateDataProvider';
import socket from '../socket_connection/socket';
import { toast } from 'react-toastify';




const LinkedInContext = createContext({

    // functions
    getAllLinkedInPageEvents: () => { },
    getLinkedInPostInfo: () => { },
    deleteLinkedInPagePostComment: () => { },
    addCommentToLinkedInPagePost: () => { },
    addLinkedInCommentReply: () => { },
})




export const LinkedInContextProvider = (props) => {
    const createDataContext = useContext(CreateDataContext)
    // eslint-disable-next-line
    const [refreshPage, setRefreshPage] = useState(false)


    // NOTE: Find parent comment of the reply
    const findParentComment = (messages, id) => {
        for (const message of messages) {
            if (message.id === id) {
                return message;
            }
            if (message.replies) {
                for (const reply of message.replies) {
                    if (reply.id === id) {
                        return message;
                    }
                }
            }
        }
        return null; // If no matching comment is found
    }

    // NOTE: Socket receiver for webhook notification
    useEffect(() => {
        // NOTE: receiving linkedin feed notification
        socket.on('linkedinpage-feed', handleFeedNotifications);

        return () => {
            socket.off('linkedinpage-feed', handleFeedNotifications);
        };
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    // NOTE: Handle the linkedin webhook feed notification
    const handleFeedNotifications = async (data) => {
        try {
            constant.eventData = data
            setRefreshPage((prev) => !prev)
            if (constant.eventData) {
                const activeAccountId = constant.activeAccount.socialId
                if (constant.activeNotificationType === "feed" && constant.eventData.type === "feed") {
                    const webhookNotificationAccountId = data.accountId
                    if (activeAccountId === webhookNotificationAccountId) {
                        await getWebhookNotificationInfo(constant.eventData)
                    }
                }
            }
        } catch (error) {
            console.error('Error while making API request:', error);
        }
    }


    // NOTE: Get webhook notification info such as comment by the notification
    const getWebhookNotificationInfo = async (data) => {
        try {
            const workspaceId = localStorage.getItem('organizationId')
            if (workspaceId === data.workspaceId) {
                const webhookNotificationId = data.postid_or_userid
                const isConversationExists = constant.conversationList.find((data) => data.postid_or_userid === webhookNotificationId)
                const accountId = constant.activeAccount?.socialId
                const accountToken = constant.activeAccount?.accessToken

                if (isConversationExists === undefined) {
                    createDataContext.handleWebhookNotificationLoading(true)
                    const event = data
                    socket.emit('create', `${accountId}_${event.postid_or_userid}`)

                    if (!event.newMessage) {
                        setTimeout(() => {
                            createDataContext.handleWebhookNotificationLoading(false)
                            if (constant.conversationList.length === 0) {
                                createDataContext.handleActiveChat(event)
                            }
                            createDataContext.handleConversationList([event, ...constant.conversationList])
                            createDataContext.handleAllConversations([event, ...constant.conversationList])
                        }, 1000)
                    } else {
                        const postResponse = await axios.post(`${constant.socialInboxUrl}inbox/linkedin/posts`, {
                            accountToken: accountToken,
                            accountId: accountId,
                            eventIds: [event.postid_or_userid]
                        })

                        const post = postResponse.data.posts[0]
                        let mediaResponse;
                        let mediaUrn;
                        let mediaType;
                        if (post.content !== undefined) {
                            mediaUrn = post.content.media.id;
                            const urnParts = mediaUrn.split(':');
                            mediaType = urnParts[urnParts.length - 2];
                            const mediaTypeKey =
                                mediaType === 'document'
                                    ? 'documents'
                                    : mediaType === 'image'
                                        ? 'images'
                                        : mediaType;
                            mediaResponse = await axios.get(
                                `${constant.cacheUrl}analytics/linkedin/rest/${mediaTypeKey}/${mediaUrn}`,
                                {
                                    headers: {
                                        Authorization: `Bearer ${accountToken}`,
                                    },
                                }
                            );
                        }

                        let eventData = {
                            name_or_caption: post.commentary,
                            postid_or_userid: post.id,
                            likes: post.statistics?.likeCount || 0,
                            comments: post.statistics?.commentCount || 0,
                            media_url: post.content === undefined ? '' : mediaResponse.data.downloadUrl,
                            created_time: post.createdAt,
                            attachments: post.content === undefined || mediaType === "video" ? [] : [
                                {
                                    media_type: mediaType,
                                    media_url: mediaType === "video" ? mediaResponse.data.thumbnail : mediaResponse.data.downloadUrl,
                                    id: mediaUrn,
                                },
                            ],
                            content: post.content === undefined ? {} : post.content,
                            conversationType: 'feed',
                            accountType: 'linkedinpage',
                            unread: 1,
                            workspaceId: workspaceId,
                            accountId: accountId,
                            isPostExists: true
                        }


                        setTimeout(() => {
                            createDataContext.handleWebhookNotificationLoading(false)
                            if (constant.conversationList.length === 0) {
                                createDataContext.handleActiveChat(eventData)
                            }
                            createDataContext.handleConversationList([eventData, ...constant.conversationList])
                            createDataContext.handleAllConversations([eventData, ...constant.conversationList])
                        }, 1000)
                    }


                } else {
                    // NOTE: this code is for the feed which is active or open
                    if (constant.activeChat?.postid_or_userid === webhookNotificationId) {
                        const commentId = data.notification.decoratedGeneratedActivity.comment.entity.split(',');
                        const formattedCommentId = commentId[1].replace(')', '');
                        const isCommentMadeFromInbox = constant.commentsMessagesMadeFromInbox.includes(formattedCommentId)
                        if (!isCommentMadeFromInbox) {
                            createDataContext.handleCommentLoading(true)
                            const response = await axios.post(`${constant.socialInboxUrl}inbox/linkedin/comment`, {
                                accountToken: accountToken,
                                postId: data.notification.decoratedGeneratedActivity.comment.object,
                                commentId: data.notification.decoratedGeneratedActivity.comment.entity
                            })
                            if (response.data.comment.parent !== undefined) {
                                const parent = findParentComment(constant.messages, response.data.comment.parent.id)
                                createDataContext.handleViewReplies({
                                    id: parent.id,
                                    isActive: true
                                })
                                const parentIndex = constant.messages.findIndex((data) => data.id === parent.id);
                                if (parent.replies === undefined) {
                                    parent.comment_count = parent.comment_count + 1
                                    parent.replies = [response.data.comment]
                                } else {
                                    parent.comment_count = parent.comment_count + 1
                                    parent.replies.unshift(response.data.comment);
                                }
                                let updatedArray = [...constant.messages];
                                updatedArray[parentIndex] = parent
                                const newComment = createDataContext.createCommentTree(updatedArray)
                                createDataContext.handleMessages(newComment)
                                createDataContext.handleActiveChat({
                                    ...constant.activeChat,
                                    comments: constant.activeChat.comments + 1
                                })
                            } else {
                                const latestComment = [response.data.comment, ...constant.messages]
                                const commentList = createDataContext.createCommentTree(latestComment)
                                createDataContext.handleMessages(commentList)
                                createDataContext.handleActiveChat({
                                    ...constant.activeChat,
                                    comments: constant.activeChat.comments + 1
                                })
                            }
                            const workspaceId = localStorage.getItem('organizationId')
                            const accountId = constant.activeAccount?.socialId
                            const unreadResponse = await axios.put(`${constant.socialInboxUrl}inbox/event/update/unread/${workspaceId}/${constant.activeChat?.postid_or_userid}/urn:li:organization:${accountId}/linkedinpage/feed`)
                            if (unreadResponse.status === 200) {
                                const updatedConversation = constant.conversationList.map(conversation => {
                                    return conversation.postid_or_userid === data.postid_or_userid
                                        ? { ...conversation, unread: 0 }
                                        : conversation;
                                });

                                createDataContext.handleConversationList(updatedConversation);
                            }
                            createDataContext.handleCommentLoading(false)
                        }

                    } else {
                        // NOTE: this code is for chat or feed which is not active
                        const updatedList = constant.conversationList.map((feed) => {
                            if (feed.postid_or_userid === data.postid_or_userid) {
                                return {
                                    ...feed,
                                    latest_message: `New Comment: ${data.notification.decoratedGeneratedActivity.comment.text}`,
                                    unread: feed.unread + 1
                                };
                            }
                            return feed;
                        });
                        createDataContext.handleConversationList(updatedList);
                    }
                }
            }
        } catch (error) {
            console.log(error)
        }
    }


    // NOTE: Get all linkedin page events and then fetch the post by the eventId(here eventId is postId)
    const getAllLinkedInPageEvents = async (loadMore) => {
        try {
            if (loadMore) {
                createDataContext.handleLoadMore(true)
                createDataContext.handleChatLoading(false)
                createDataContext.handleConversationLoading(false);
            } else {
                createDataContext.handleConversationLoading(true);
                createDataContext.handlePaginationInfo(null)
            }
            const workspaceId = localStorage.getItem('organizationId')
            const accountId = createDataContext.activeAccount?.socialId
            const accountToken = createDataContext.activeAccount?.accessToken

            if (constant.cancelToken) {
                constant.cancelToken.cancel(); // Cancel the previous request before making a new request
            }

            // Create a new CancelToken
            constant.cancelToken = axios.CancelToken.source();

            let eventIds = []
            const response = await axios.get(`${constant.socialInboxUrl}inbox/event/${workspaceId}/urn:li:organization:${accountId}/linkedinpage/feed?page=${constant.paginationInfo?.page || 1}&limit=${constant.paginationInfo?.limit || 10}`, {
                cancelToken: constant.cancelToken.token
            })
            response.data.events.forEach((data) => {
                eventIds = [...eventIds, data.eventId]
            })

            if (eventIds.length > 0) {
                const postResponse = await axios.post(`${constant.socialInboxUrl}inbox/linkedin/posts`, {
                    accountToken: accountToken,
                    accountId: accountId,
                    eventIds: eventIds
                }, {
                    cancelToken: constant.cancelToken.token
                })

                let updatedPagePosts = await Promise.all(
                    response.data.events.map(async (data, index) => {
                        const post = postResponse.data.posts.find((post) => data.eventId === post.id);

                        if (!post) {
                            return null; // Handle the case where there's no matching post
                        }

                        let mediaResponse;
                        let mediaUrn;
                        let mediaType;
                        if (post.content !== undefined) {
                            mediaUrn = post.content.media.id;
                            const urnParts = mediaUrn.split(':');
                            mediaType = urnParts[urnParts.length - 2];
                            const mediaTypeKey =
                                mediaType === 'document'
                                    ? 'documents'
                                    : mediaType === 'image'
                                        ? 'images'
                                        : mediaType;
                            mediaResponse = await axios.get(
                                `${constant.cacheUrl}analytics/linkedin/rest/${mediaTypeKey}/${mediaUrn}`,
                                {
                                    headers: {
                                        Authorization: `Bearer ${accountToken}`,
                                    },
                                }
                            );
                        }


                        return {
                            name_or_caption: post.commentary,
                            postid_or_userid: post.id,
                            likes: post.statistics?.likeCount || 0,
                            comments: post.statistics?.commentCount || 0,
                            media_url: post.content === undefined ? '' : mediaResponse.data.downloadUrl,
                            created_time: post.createdAt,
                            attachments: post.content === undefined || mediaType === "video" ? [] : [
                                {
                                    media_type: mediaType,
                                    media_url: mediaType === "video" ? mediaResponse.data.thumbnail : mediaResponse.data.downloadUrl,
                                    id: mediaUrn,
                                },
                            ],
                            content: post.content === undefined ? {} : post.content,
                            conversationType: 'feed',
                            accountType: 'linkedinpage',
                            unread: index === 0 ? 0 : data.unread,
                            isPostExists: true
                        };
                    })
                );

                // Remove any null entries
                updatedPagePosts = updatedPagePosts.filter(Boolean);

                if (updatedPagePosts[0].unread > 0) {
                    const response = await axios.put(`${constant.socialInboxUrl}inbox/event/update/unread/${workspaceId}/${updatedPagePosts[0].postid_or_userid}/urn:li:organization:${accountId}/linkedinpage/feed`)
                    if (response.status === 200) {
                        let obj = {
                            ...updatedPagePosts[0],
                            unread: 0
                        }
                        createDataContext.handleActiveChat(obj);
                    }
                } else {
                    createDataContext.handleActiveChat(updatedPagePosts[0]);
                }

                updatedPagePosts.forEach((data) => {
                    socket.emit('create', `${accountId}_${data.postid_or_userid}`)
                })

                createDataContext.handleConversationList(updatedPagePosts)
                createDataContext.handleAllConversations(updatedPagePosts)
                createDataContext.handlePaginationInfo({
                    ...response.data.pagination,
                    page: Number(response.data.pagination.page) + 1
                })
            }
            createDataContext.handleConversationLoading(false)
        } catch (error) {
            createDataContext.handleConversationLoading(false)
            console.log(error)
        }
    }


    // NOTE: Get comments of active linkedin feed 
    const getLinkedInPostInfo = async () => {
        try {
            createDataContext.handleMoreOptions(false)
            createDataContext.handleChatLoading(true)
            const accountToken = createDataContext.activeAccount?.accessToken
            const postId = createDataContext.activeChat?.postid_or_userid

            if (constant.cancelToken) {
                constant.cancelToken.cancel(); // Cancel the previous request before making a new request
            }

            // Create a new CancelToken
            constant.cancelToken = axios.CancelToken.source();
            const response = await axios.post(`${constant.socialInboxUrl}inbox/linkedin/post/comments`, {
                accountToken: accountToken,
                postId: postId,
            }, {
                cancelToken: constant.cancelToken.token
            })

            const comments = response.data.comments.map((comment) => ({
                ...comment,
                media: comment.media
                    ? [
                        {
                            id: comment.media[0].id,
                            type: comment.media[0].type === "IMAGE" ? "image" : "video",
                            url: comment.media[0].url
                        }
                    ]
                    : null,
                replies: comment.replies || []
            }));

            const commentList = createDataContext.createCommentTree(comments)
            createDataContext.handleAllMessages(commentList)
            createDataContext.handleMessages(commentList)
            createDataContext.handleChatLoading(false)
        } catch (error) {
            console.log(error)
        }
    }

    // NOTE: Delete linkedin page comment
    const deleteLinkedInPagePostComment = async (commentUrn) => {
        try {
            createDataContext.handleDeleting({
                isLoading: true,
                id: commentUrn
            })
            const accountToken = createDataContext.activeAccount?.accessToken
            const postId = createDataContext.activeChat?.postid_or_userid
            const ids = commentUrn.match(/\(([^,]+),([^)]+)\)/);
            const comment_id = ids[2]
            await axios.post(`${constant.socialInboxUrl}inbox/linkedin/comment/delete`, {
                accountToken: accountToken,
                postId: postId,
                actorId: `urn:li:organization:${createDataContext.activeAccount?.socialId}`,
                commentId: comment_id
            })
            const removedComment = createDataContext.recursivelyRemoveComment(createDataContext.messages, commentUrn);
            createDataContext.handleMessages(removedComment)
            createDataContext.handleDeleting({
                isLoading: false,
                id: ''
            })
            toast.success("Comment deleted successfully.")
        } catch (error) {
            console.log(error)
            toast.error('Oops! Something went wrong. Please try again.');
            createDataContext.handleDeleting({
                isLoading: false,
                id: ''
            })
        }
    }

    // NOTE: Add comment to linkedin page post
    const addCommentToLinkedInPagePost = async () => {
        try {
            createDataContext.handleSendingMessage(true)
            const accountToken = createDataContext.activeAccount?.accessToken
            const postId = createDataContext.activeChat?.postid_or_userid
            let body = {
                message: createDataContext.text,
                accountToken: accountToken,
                actorId: `urn:li:organization:${createDataContext.activeAccount?.socialId}`,
                id: postId,
            }

            if (createDataContext.messageMedia.length > 0) {
                body.media = [{
                    id: createDataContext.messageMedia[0].id,
                    url: createDataContext.messageMedia[0].URL,
                    type: 'IMAGE'
                }]
            }

            const response = await axios.post(`${constant.socialInboxUrl}inbox/linkedin/comment/create`, body)

            const currentDate = new Date();
            const timestamp = currentDate.getTime();

            let comment = {
                from: {
                    id: createDataContext.activeAccount?.socialId,
                    name: createDataContext.activeAccount?.name,
                    profile_pic: createDataContext.activeAccount?.profileURL
                },
                id: `urn:li:comment:(${postId},${response.data.comment_id})`,
                created_time: timestamp,
                comment_count: 0,
                like_count: 0,
                message: createDataContext.text
            }

            if (createDataContext.messageMedia.length > 0) {
                comment.media = [{
                    id: createDataContext.messageMedia[0].id,
                    url: createDataContext.messageMedia[0].URL,
                    type: 'IMAGE'
                }]
            }

            const newArray = [comment, ...createDataContext.messages]
            const newComment = createDataContext.createCommentTree(newArray)
            constant.commentsMessagesMadeFromInbox.push(response.data.comment_id)
            createDataContext.handleMessages(newComment)
            createDataContext.handleMessageMedia([])
            createDataContext.handleText("", "text")
            createDataContext.handleSendingMessage(false)
        } catch (error) {
            console.log(error)
            createDataContext.handleSendingMessage(false)
            createDataContext.handleReplyText("", "text")
            toast.error('Oops! Something went wrong. Please try again.');
        }
    }

    // NOTE: Add reply to linkedin comment
    const addLinkedInCommentReply = async (id) => {
        try {
            createDataContext.handleSendingReply(true);
            const accountToken = createDataContext.activeAccount?.accessToken;
            const postId = createDataContext.activeChat?.postid_or_userid;
            const response = await axios.post(`${constant.socialInboxUrl}inbox/linkedin/comment/create`, {
                message: createDataContext.replyText,
                accountToken,
                actorId: `urn:li:organization:${createDataContext.activeAccount?.socialId}`,
                id: postId,
                parentComment: id
            });

            const currentDate = Date.now();
            const parent = findParentComment(createDataContext.messages, id);
            const newReply = {
                from: {
                    id: createDataContext.activeAccount?.socialId,
                    name: createDataContext.activeAccount?.name,
                    profile_pic: createDataContext.activeAccount?.profileURL
                },
                id: `urn:li:comment:(${postId},${response.data.comment_id})`,
                created_time: currentDate,
                comment_count: parent.comment_count + 1,
                like_count: 0,
                message: createDataContext.replyText,
                parent
            };

            const updatedMessages = createDataContext.messages.map(msg => {
                return msg.id === parent.id
                    ? {
                        ...msg,
                        comment_count: msg.comment_count + 1,
                        replies: [newReply, ...msg.replies || []]
                    }
                    : msg;
            });


            const newCommentTree = createDataContext.createCommentTree(updatedMessages);
            constant.commentsMessagesMadeFromInbox.push(response.data.comment_id);
            createDataContext.handleMessages(newCommentTree);
            createDataContext.handleReplyText("", "text");
            createDataContext.handleSendingReply(false);
        } catch (error) {
            toast.error('Oops! Something went wrong. Please try again.');
            createDataContext.handleReplyText("", "text");
            createDataContext.handleSendingReply(false);
            console.error(error);
        }
    };



    const context = {
        // functions
        getAllLinkedInPageEvents: getAllLinkedInPageEvents,
        getLinkedInPostInfo: getLinkedInPostInfo,
        deleteLinkedInPagePostComment: deleteLinkedInPagePostComment,
        addCommentToLinkedInPagePost: addCommentToLinkedInPagePost,
        addLinkedInCommentReply: addLinkedInCommentReply,
    }

    return (
        <LinkedInContext.Provider value={context}>{props.children}</LinkedInContext.Provider>
    )
}


export default LinkedInContext