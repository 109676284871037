import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { CreateDataProvider } from './store/CreateDataProvider';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ButtonContextProvider } from './store/ButtonProvider';
import { FacebookContextProvider } from './store/facebookProvider';
import { InstagramContextProvider } from './store/instagramProvider';
import { LinkedInContextProvider } from './store/linkedinProvider';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <>
    <ButtonContextProvider>
      <CreateDataProvider>
        <FacebookContextProvider>
          <InstagramContextProvider>
            <LinkedInContextProvider>
              <App />
            </LinkedInContextProvider>
          </InstagramContextProvider>
        </FacebookContextProvider>
      </CreateDataProvider>
    </ButtonContextProvider>
    <ToastContainer />
  </>
);


