import { createContext, useContext, useState, useEffect } from 'react';
import CreateDataContext from './CreateDataProvider';
import axios from 'axios';
import socket from '../socket_connection/socket';
import constant from '../constant';
import { toast, Bounce } from 'react-toastify';
import { v4 as uuidv4 } from 'uuid';


const InstagramContext = createContext({


    // Functions
    // getInstagramPosts: () => { },
    getInstagramPostInfo: () => { },
    restructureTheInstagramComment: () => { },
    addCommentToInstagramPost: () => { },
    deleteInstagramComment: () => { },
    addInstagramCommentReply: () => { },
    getAllInstagramPageEvents: () => { },
    getInstagramPageConversations: () => { },
    getInstagramPageMessages: () => { },
    sendMessageToUserInstagramDm: () => { },
    getAllInstagramPageMentionsEvents: () => { },
    getUsersTaggedPost: () => { },
    getPostsMentionData: () => { },
    replyToMentionPostComment: () => { },
    reactToInstaMessage: () => { }
})

export const InstagramContextProvider = (props) => {
    const createDataContext = useContext(CreateDataContext)
    // eslint-disable-next-line
    const [refreshPage, setRefreshPage] = useState(false)

    // NOTE: restructure the instagram comment from api
    const restructureTheInstagramComment = (comments) => {
        return comments.map((data) => {
            return {
                from: {
                    name: data?.from === undefined ? data.username : data.from?.username,
                    id: data?.from === undefined ? "" : data.from?.id
                },
                id: data.id,
                comment_count: data.replies === undefined ? 0 : data.replies.data.length,
                like_count: data.like_count,
                message: data.text,
                created_time: data.timestamp,
                replies: data.replies === undefined ? [] : data.replies.data.map((reply) => {
                    return {
                        from: {
                            name: reply?.from === undefined ? reply.username : reply.from.username,
                            id: reply?.from === undefined ? "" : reply.from.id,
                        },
                        comment_count: 0,
                        created_time: reply.timestamp,
                        like_count: reply.like_count,
                        id: reply.id,
                        message: reply.text,
                        parent: {
                            id: reply.parent_id
                        }

                    }
                }).reverse()
            }
        })
    }


    // NOTE: Find parent comment of the reply
    const findParentComment = (messages, id) => {
        for (const message of messages) {
            if (message.id === id) {
                return message;
            }
            if (message.replies) {
                for (const reply of message.replies) {
                    if (reply.id === id) {
                        return message;
                    }
                }
            }
        }
        return null; // If no matching comment is found
    }

    // NOTE: Check if the comment or reply is made from creatosaurus inbox if yes then don't show the webhook notification because the comment is already in system
    const isIdPresent = (dataArray, targetId) => {
        for (const item of dataArray) {
            if (item.id === targetId) {
                return true; // Found in the main array
            }

            if (item.replies && item.replies.length > 0) {
                // Check in replies array
                if (isIdPresent(item.replies, targetId)) {
                    return true; // Found in replies
                }
            }
        }

        return false; // ID not found
    };

    const convertUnixTimestampToISO = (unixTimestamp) => {
        const date = new Date(unixTimestamp * 1000);

        const year = date.getUTCFullYear();
        const month = String(date.getUTCMonth() + 1).padStart(2, '0');
        const day = String(date.getUTCDate()).padStart(2, '0');
        const hours = String(date.getUTCHours()).padStart(2, '0');
        const minutes = String(date.getUTCMinutes()).padStart(2, '0');
        const seconds = String(date.getUTCSeconds()).padStart(2, '0');

        const isoString = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}+0000`;

        return isoString;
    }


    // NOTE: Socket receiver for webhook notification
    useEffect(() => {
        // NOTE: receiving facebook page feed notification
        socket.on('instagram-feed', handleNotifications)
        socket.on('instagram-message', handleNotifications)
        socket.on('instagram-mentions', handleNotifications)

        return () => {
            socket.off('instagram-feed', handleNotifications);
            socket.off('instagram-message', handleNotifications)
            socket.off('instagram-mentions', handleNotifications)
        };
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    // NOTE: Handle the facebook page webhook feed,messenger & mentions notification
    const handleNotifications = async (data) => {
        try {

            constant.eventData = data
            setRefreshPage((prev) => !prev)
            if (constant.eventData) {
                const activeAccountId = constant.activeAccount.socialId
                if (constant.activeNotificationType === "feed" && constant.eventData.type === "feed") {
                    const webhookNotificationAccountId = constant.eventData.accountId
                    if (activeAccountId === webhookNotificationAccountId) {
                        await getWebhookNotificationInfo(constant.eventData)
                    }
                } else if (constant.activeNotificationType === "messages" && constant.eventData.type === "messenger") {
                    const webhookNotificationAccountId = constant.eventData.message === undefined ? constant.eventData.to.id : constant.eventData.message.to.id
                    if (activeAccountId === webhookNotificationAccountId) {
                        await getMessengerWebhookNotificationInfo(constant.eventData)
                    }
                } else if (constant.activeNotificationType === "mentions" && constant.eventData.type === "mentions") {
                    const webhookNotificationAccountId = constant.eventData.accountId
                    if (activeAccountId === webhookNotificationAccountId) {
                        await getMentionWebhookInfo(constant.eventData)
                    }
                }
            }
        } catch (error) {
            console.error('Error while making API request:', error);
        }
    }

    // NOTE: Get mentions webhook notfication 
    const getMentionWebhookInfo = async (data) => {
        const workspaceId = localStorage.getItem('organizationId')
        if (workspaceId === data.workspaceId) {
            const webhookNotificationId = data.postid_or_userid
            const isConversationExists = constant.conversationList.find((data) => data.postid_or_userid === webhookNotificationId)
            const accountId = constant.activeAccount.socialId
            const accountToken = constant.activeAccount.accessToken
            if (isConversationExists === undefined) {
                createDataContext.handleWebhookNotificationLoading(true)
                const event = data
                socket.emit('create', `${accountId}_${event.postid_or_userid}`)
                if (!event.newMessage) {
                    setTimeout(() => {
                        createDataContext.handleWebhookNotificationLoading(false)
                        if (constant.conversationList.length === 0) {
                            createDataContext.handleActiveChat(event)
                        }
                        createDataContext.handleConversationList([event, ...constant.conversationList])
                        createDataContext.handleAllConversations([event, ...constant.conversationList])
                    }, 1000)
                } else {
                    const commentId = data.notification.value.comment_id
                    let mentionResponse;
                    if (commentId) {
                        mentionResponse = await axios.get(`https://graph.facebook.com/v17.0/${accountId}?access_token=${accountToken}&fields=mentioned_comment.comment_id(${commentId}){id,media{id,media_url,like_count,comments_count,caption,timestamp,permalink,username}}`);
                    } else {
                        mentionResponse = await axios.get(`https://graph.facebook.com/v17.0/${accountId}?access_token=${accountToken}&fields=mentioned_media.media_id(${webhookNotificationId}){username,permalink,media_url,caption,thumbnail_url,timestamp,comments_count,like_count,children{media_type,media_url,thumbnail_url}}`);
                    }

                    let mentionPost;

                    if (commentId) {
                        mentionPost = {
                            name_or_caption: mentionResponse.data.mentioned_comment.media.caption === undefined ? "No Caption" : mentionResponse.data.mentioned_comment.media.caption,
                            postid_or_userid: mentionResponse.data.mentioned_comment.media.id,
                            likes: mentionResponse.data.mentioned_comment.media.like_count,
                            comments: mentionResponse.data.mentioned_comment.media.comments_count,
                            media_url: mentionResponse.data.mentioned_comment.media.media_url === undefined ? mentionResponse.data.mentioned_comment.media.thumbnail_url : mentionResponse.data.mentioned_comment.media.media_url,
                            created_time: convertUnixTimestampToISO(mentionResponse.data.mentioned_comment.media.timestamp),
                            attachments: [{
                                media_type: 'image',
                                media_url: mentionResponse.data.mentioned_comment.media.media_url === undefined ? mentionResponse.data.mentioned_comment.media.thumbnail_url : mentionResponse.data.mentioned_comment.media.media_url,
                                id: uuidv4()
                            }],
                            type: 'mentions',
                            conversationType: 'mentions',
                            accountType: 'instagram',
                            unread: 1,
                            workspaceId: data.workspaceId,
                            accountId: accountId,
                            postLink: mentionResponse.data.mentioned_comment.media.permalink,
                            owner: mentionResponse.data.mentioned_comment.media.username,
                            isMentionInCaption: false
                        }
                    } else {
                        mentionPost = {
                            name_or_caption: mentionResponse.data.mentioned_media.caption === undefined ? "No Caption" : mentionResponse.data.mentioned_media.caption,
                            postid_or_userid: mentionResponse.data.mentioned_media.id,
                            likes: mentionResponse.data.mentioned_media.like_count,
                            comments: mentionResponse.data.mentioned_media.comments_count,
                            media_url: mentionResponse.data.mentioned_media.media_url === undefined ? mentionResponse.data.mentioned_media.thumbnail_url : mentionResponse.data.mentioned_media.media_url,
                            created_time: convertUnixTimestampToISO(mentionResponse.data.mentioned_media.timestamp),
                            attachments: [{
                                media_type: 'image',
                                media_url: mentionResponse.data.mentioned_media.media_url === undefined ? mentionResponse.data.mentioned_media.thumbnail_url : mentionResponse.data.mentioned_media.media_url,
                                id: uuidv4()
                            }],
                            type: 'mentions',
                            conversationType: 'mentions',
                            accountType: 'instagram',
                            unread: 1,
                            workspaceId: data.workspaceId,
                            accountId: accountId,
                            postLink: mentionResponse.data.mentioned_media.permalink,
                            owner: mentionResponse.data.mentioned_media.username,
                            isMentionInCaption: true
                        }
                    }

                    setTimeout(() => {
                        createDataContext.handleWebhookNotificationLoading(false)
                        if (constant.conversationList.length === 0) {
                            createDataContext.handleActiveChat(mentionPost)
                        }
                        createDataContext.handleConversationList([mentionPost, ...constant.conversationList])
                        createDataContext.handleAllConversations([mentionPost, ...constant.conversationList])
                    }, 1000)

                }
            } else {
                // NOTE: this code is for the feed which is active or open
                if (constant.activeChat?.postid_or_userid === webhookNotificationId) {
                    // NOTE: this code is for the feed which is active or open
                    const commentId = data.notification.value.comment_id
                    const response = await axios.get(`https://graph.facebook.com/v17.0/${accountId}?access_token=${accountToken}&fields=mentioned_comment.comment_id(${commentId}){username,id,text,timestamp,like_count,replies{username,id,text,timestamp,like_count,parent_id}}`);
                    const commentInfo = response.data.mentioned_comment
                    const isCommentMadeFromInbox = isIdPresent(constant.messages, commentId)
                    // Get the current Unix timestamp in seconds
                    const currentDate = new Date();
                    const timestamp = Math.floor(currentDate.getTime() / 1000);
                    if (!isCommentMadeFromInbox) {
                        createDataContext.handleCommentLoading(true)
                        let newNotification = {
                            comment_count: 0,
                            like_count: 0,
                            created_time: convertUnixTimestampToISO(timestamp),
                            from: {
                                name: commentInfo.username,
                                id: '',
                                profile_pic: undefined,
                            },
                            id: commentInfo.id,
                            message: commentInfo.text
                        }

                        setTimeout(() => {
                            if (!createDataContext.commentLoading) {
                                const latestComment = [newNotification, ...constant.messages];
                                const commentList = createDataContext.createCommentTree(latestComment);

                                createDataContext.handleMessages(commentList);
                                createDataContext.handleActiveChat({
                                    ...constant.activeChat,
                                    comments: constant.activeChat.comments + 1
                                });
                            }
                            createDataContext.handleCommentLoading(false);
                        }, 1000);

                        const accountId = constant.activeAccount?.socialId
                        const response = await axios.put(`${constant.socialInboxUrl}inbox/event/update/unread/${workspaceId}/${constant.activeChat?.postid_or_userid}/${accountId}/instagram/mentions`)
                        if (response.status === 200) {
                            const updatedConversation = constant.conversationList.map(conversation => {
                                return conversation.postid_or_userid === createDataContext.activeChat?.postid_or_userid
                                    ? { ...conversation, unread: 0 }
                                    : conversation;
                            });

                            createDataContext.handleConversationList(updatedConversation);
                        }


                        toast.info('You have new comments.',
                            {
                                position: "top-center",
                                autoClose: 2000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                                theme: "light",
                                transition: Bounce, // Use the built-in Bounce transition
                            }
                        );
                    }
                } else {
                    // NOTE: this code is for chat or feed which is not active
                    const postId = data.notification.value.media_id;
                    const commentId = data.notification.value.comment_id
                    const response = await axios.get(`https://graph.facebook.com/v17.0/${accountId}?access_token=${accountToken}&fields=mentioned_comment.comment_id(${commentId}){text}`);
                    const updatedList = constant.conversationList.map((feed) => {
                        if (feed.postid_or_userid === postId) {
                            return {
                                ...feed,
                                latest_message: `New Comment: ${response.data.mentioned_comment.text}`,
                                unread: feed.unread + 1
                            };
                        }
                        return feed;
                    });

                    const updatedListWithMovedItem = [
                        updatedList.find((feed) => feed.postid_or_userid === postId),
                        ...updatedList.filter((feed) => feed.postid_or_userid !== postId)
                    ];

                    createDataContext.handleConversationList(updatedListWithMovedItem);
                }
            }
        }
    }

    // NOTE: Get webhook notification info such as comment by the notification
    const getWebhookNotificationInfo = async (data) => {
        try {
            const workspaceId = localStorage.getItem('organizationId')
            if (workspaceId === data.workspaceId) {
                const webhookNotificationId = data.postid_or_userid
                const isConversationExists = constant.conversationList.find((data) => data.postid_or_userid === webhookNotificationId)
                const accountId = constant.activeAccount.socialId
                if (isConversationExists === undefined) {
                    createDataContext.handleWebhookNotificationLoading(true)
                    const event = data
                    socket.emit('create', `${accountId}_${event.postid_or_userid}`)
                    if (!event.newMessage) {
                        setTimeout(() => {
                            createDataContext.handleWebhookNotificationLoading(false)
                            if (constant.conversationList.length === 0) {
                                createDataContext.handleActiveChat(event)
                            }
                            createDataContext.handleConversationList([event, ...constant.conversationList])
                            createDataContext.handleAllConversations([event, ...constant.conversationList])
                        }, 1000)
                    } else {
                        const postResponse = await axios.get(`https://graph.facebook.com/v17.0/${event.postid_or_userid}?fields=permalink,media_url,caption,thumbnail_url,timestamp,comments_count,like_count,children{media_type,media_url,thumbnail_url}&access_token=${constant.activeAccount?.accessToken}`);
                        let pagePost = {
                            name_or_caption: postResponse.data.caption === undefined ? "No Caption" : postResponse.data.caption,
                            postid_or_userid: postResponse.data.id,
                            likes: postResponse.data.like_count,
                            comments: postResponse.data.comments_count,
                            media_url: postResponse.data.media_url === undefined ? postResponse.data.thumbnail_url : postResponse.data.media_url,
                            created_time: postResponse.data.timestamp,
                            attachments: [{
                                media_type: 'image',
                                media_url: postResponse.data.media_url === undefined ? postResponse.data.thumbnail_url : postResponse.data.media_url,
                                id: uuidv4()
                            }],
                            type: 'feed',
                            conversationType: 'feed',
                            accountType: 'instagram',
                            unread: 1,
                            workspaceId: workspaceId,
                            accountId: accountId,
                            postLink: postResponse.permalink,
                            isPostExists: true
                        }
                        setTimeout(() => {
                            createDataContext.handleWebhookNotificationLoading(false)
                            if (constant.conversationList.length === 0) {
                                createDataContext.handleActiveChat(pagePost)
                            }
                            createDataContext.handleConversationList([pagePost, ...constant.conversationList])
                            createDataContext.handleAllConversations([pagePost, ...constant.conversationList])
                        }, 1000)
                    }
                } else {
                    // NOTE: this code is for the feed which is active or open
                    if (constant.activeChat?.postid_or_userid === webhookNotificationId) {
                        const commentParentId = data.notification.value.parent_id
                        const commentId = data.notification.value.id
                        const isCommentMadeFromInbox = isIdPresent(constant.messages, commentId)
                        // Get the current Unix timestamp in seconds
                        const currentDate = new Date();
                        const timestamp = Math.floor(currentDate.getTime() / 1000);
                        //   NOTE: not showing the webhook notification if the comment is made from inbox
                        if (!isCommentMadeFromInbox) {
                            createDataContext.handleNewMessageCount(constant.newMessagesCount + 1)
                            // NOTE: checking if it is a new comment or reply
                            if (commentParentId === undefined) {
                                createDataContext.handleCommentLoading(true)
                                let newNotification = {
                                    comment_count: 0,
                                    like_count: 0,
                                    created_time: convertUnixTimestampToISO(timestamp),
                                    from: {
                                        name: data.notification.value.from.username,
                                        id: data.notification.value.from.id,
                                        profile_pic: undefined,
                                    },
                                    id: data.notification.value.id,
                                    message: data.notification.value.text
                                }

                                setTimeout(() => {
                                    if (!createDataContext.commentLoading) {
                                        const latestComment = [newNotification, ...constant.messages];
                                        const commentList = createDataContext.createCommentTree(latestComment);

                                        createDataContext.handleMessages(commentList);
                                        createDataContext.handleActiveChat({
                                            ...constant.activeChat,
                                            comments: constant.activeChat.comments + 1
                                        });
                                    }
                                    createDataContext.handleCommentLoading(false);
                                }, 1000);

                                const accountId = constant.activeAccount?.socialId
                                const response = await axios.put(`${constant.socialInboxUrl}inbox/event/update/unread/${workspaceId}/${constant.activeChat?.postid_or_userid}/${accountId}/instagram/feed`)
                                if (response.status === 200) {
                                    const updatedConversation = constant.conversationList.map(conversation => {
                                        return conversation.postid_or_userid === createDataContext.activeChat?.postid_or_userid
                                            ? { ...conversation, unread: 0 }
                                            : conversation;
                                    });

                                    createDataContext.handleConversationList(updatedConversation);
                                }
                            } else {
                                const parent = findParentComment(constant.messages, commentParentId)
                                createDataContext.handleViewReplies({
                                    id: parent.id,
                                    isActive: true
                                })
                                const parentIndex = constant.messages.findIndex((data) => data.id === parent.id);
                                let newNotification = {
                                    comment_count: 0,
                                    like_count: 0,
                                    created_time: convertUnixTimestampToISO(timestamp),
                                    from: {
                                        name: data.notification.value.from.username,
                                        id: data.notification.value.from.id,
                                        profile_pic: undefined,
                                    },
                                    id: data.notification.value.id,
                                    message: data.notification.value.text
                                }

                                if (parent.replies === undefined) {
                                    parent.comment_count = parent.comment_count + 1
                                    parent.replies = [newNotification]
                                } else {
                                    parent.comment_count = parent.comment_count + 1
                                    parent.replies.unshift(newNotification);
                                }
                                let updatedArray = [...constant.messages];
                                updatedArray[parentIndex] = parent
                                const newComment = createDataContext.createCommentTree(updatedArray)
                                createDataContext.handleMessages(newComment)
                                createDataContext.handleActiveChat({
                                    ...constant.activeChat,
                                    comments: constant.activeChat.comments + 1
                                })

                                const accountId = constant.activeAccount?.socialId
                                const response = await axios.put(`${constant.socialInboxUrl}inbox/event/update/unread/${workspaceId}/${constant.activeChat?.postid_or_userid}/${accountId}/instagram/feed`)
                                if (response.status === 200) {
                                    const updatedConversation = constant.conversationList.map(conversation => {
                                        return conversation.postid_or_userid === constant.activeChat?.postid_or_userid
                                            ? { ...conversation, unread: 0 }
                                            : conversation;
                                    });

                                    createDataContext.handleConversationList(updatedConversation);
                                }
                            }

                            toast.info('You have new comments.',
                                {
                                    position: "top-center",
                                    autoClose: 2000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                    theme: "light",
                                    transition: Bounce, // Use the built-in Bounce transition
                                }
                            );
                        }
                    } else {
                        // NOTE: this code is for chat or feed which is not active
                        const postId = data.notification.value.media.id;
                        const updatedList = constant.conversationList.map((feed) => {
                            if (feed.postid_or_userid === postId) {
                                return {
                                    ...feed,
                                    latest_message: `New Comment: ${data.notification.value.text}`,
                                    unread: feed.unread + 1
                                };
                            }
                            return feed;
                        });

                        const updatedListWithMovedItem = [
                            updatedList.find((feed) => feed.postid_or_userid === postId),
                            ...updatedList.filter((feed) => feed.postid_or_userid !== postId)
                        ];

                        createDataContext.handleConversationList(updatedListWithMovedItem);
                    }
                }
            }
        } catch (error) {
            console.log(error)
        }
    }


    const getCurrentTimestamp = () => {
        // Get the current date and time
        const currentDate = new Date();

        // Format the date to ISO string
        const isoString = currentDate.toISOString();

        // Split the ISO string to get the date and time parts separately
        const [date, time] = isoString.split('T');

        // Get the time without the milliseconds and the 'Z' at the end
        const timeWithoutMilliseconds = time.split('.')[0];

        // Get the timezone offset in minutes and convert it to hours and minutes
        const timezoneOffset = currentDate.getTimezoneOffset();
        const offsetHours = String(Math.floor(Math.abs(timezoneOffset) / 60)).padStart(2, '0');
        const offsetMinutes = String(Math.abs(timezoneOffset) % 60).padStart(2, '0');
        const offsetSign = timezoneOffset <= 0 ? '+' : '-';

        // Combine the date, time, and timezone offset to match the desired format
        const formattedTimestamp = `${date}T${timeWithoutMilliseconds}${offsetSign}${offsetHours}${offsetMinutes}`;

        return formattedTimestamp;
    }




    // NOTE: Get Instagram page posts events

    const getAllInstagramPageEvents = async (loadMore) => {
        try {
            if (loadMore) {
                createDataContext.handleLoadMore(true)
                createDataContext.handleChatLoading(false)
                createDataContext.handleConversationLoading(false);
            } else {
                createDataContext.handleConversationLoading(true);
                createDataContext.handlePaginationInfo(null)
            }

            const accountId = createDataContext.activeAccount?.socialId;
            const accountToken = createDataContext.activeAccount?.accessToken;
            const workspaceId = localStorage.getItem('organizationId');

            if (constant.cancelToken) {
                constant.cancelToken.cancel(); // Cancel the previous request before making a new request
            }

            // Create a new CancelToken
            constant.cancelToken = axios.CancelToken.source();

            // Fetch event IDs from the socialInbox API
            const eventResponse = await axios.get(`${constant.socialInboxUrl}inbox/event/${workspaceId}/${accountId}/instagram/feed?page=${constant.paginationInfo?.page || 1}&limit=${constant.paginationInfo?.limit || 10}`, {
                cancelToken: constant.cancelToken.token
            });
            const eventIds = eventResponse.data.events.map(data => data.eventId);


            // Fetch page posts in parallel using Promise.all
            const pagePostsPromises = eventIds.map(async (postId) => {
                try {
                    const response = await axios.get(`https://graph.facebook.com/v17.0/${postId}?access_token=${accountToken}&fields=permalink,media_url,caption,thumbnail_url,timestamp,comments_count,like_count,children{media_type,media_url,thumbnail_url}`, {
                        cancelToken: constant.cancelToken.token
                    });
                    return response.data;
                } catch (error) {
                    const errorMessage = error.response.data.error.message
                    const notFoundMessage = 'does not exist'
                    const postNotFound = errorMessage.includes(notFoundMessage)
                    if (postNotFound) {
                        let post = {
                            permalink: "not found",
                            media_url: "not found",
                            caption: "This post has been deleted or does not exist.",
                            timestamp: getCurrentTimestamp(),
                            comments_count: 0,
                            like_count: 0,
                            id: postId,
                            isPostExists: false
                        }
                        return post
                    } else {
                        console.error(`Error fetching data for postId ${postId}:`, error.message);
                        return null;
                    }
                }
            });

            // Wait for all promises to complete
            const pagePostsData = await Promise.all(pagePostsPromises);


            // Filter out the results that are null (requests that failed)
            const pagePosts = pagePostsData.filter(data => data !== null);

            const updatedPagePosts = pagePosts.map((post, index) => {
                const registeredPost = eventResponse.data.events.find((data) => data.eventId === post.id);
                if (!registeredPost) return null; // Return early if registeredPost is not found
                return {
                    name_or_caption: post.caption === undefined ? "No Caption" : post.caption,
                    postid_or_userid: post.id,
                    likes: post.like_count,
                    comments: post.comments_count,
                    media_url: post.thumbnail_url === undefined ? post.media_url : post.thumbnail_url,
                    created_time: post.timestamp,
                    attachments: post.children === undefined ? [{
                        media_type: "image",
                        media_url: post.thumbnail_url ? post.thumbnail_url : post.media_url,
                        id: ""
                    }] : post.children.data.map((data) => {
                        return {
                            media_type: data.media_type === "IMAGE" ? "image" : "video",
                            media_url: data.media_url,
                            id: data.id
                        }
                    }),
                    conversationType: 'feed',
                    accountType: "instagram",
                    unread: index === 0 ? 0 : registeredPost?.unread,
                    postLink: post.permalink,
                    isAutomationOn: registeredPost.isAutomationOn || false,
                    automationMessage: registeredPost.autmationMessage || '',
                    automationTrackingKeyword: registeredPost.automationTrackingKeyword || '',
                    commentAutomationText: registeredPost.commentAutomationText || '',
                    isPostExists: post.isPostExists === undefined ? true : false
                }

            }).filter(Boolean); // Remove null values from the resulting array
            if (eventIds.length === 0) {
                createDataContext.handleConversationList([])
                createDataContext.handleAllConversations([])
            } else {
                if (updatedPagePosts[0].unread > 0) {
                    const response = await axios.put(`${constant.socialInboxUrl}inbox/event/update/unread/${workspaceId}/${updatedPagePosts[0].postid_or_userid}/${accountId}/instagram/feed`)
                    if (response.status === 200) {
                        let obj = {
                            ...updatedPagePosts[0],
                            unread: 0
                        }
                        createDataContext.handleActiveChat(obj);
                    }
                } else {
                    if (!loadMore) {
                        createDataContext.handleActiveChat(updatedPagePosts[0]);
                    }
                }
            }
            createDataContext.handleConversationList([...constant.conversationList, ...updatedPagePosts]);
            createDataContext.handleAllConversations([...constant.allConversationList, ...updatedPagePosts]);
            createDataContext.handlePaginationInfo({
                ...eventResponse.data.pagination,
                page: Number(eventResponse.data.pagination.page) + 1
            })
            eventIds.forEach((data) => {
                socket.emit('create', data);
            });
            createDataContext.handleLoadMore(false)
            createDataContext.handleConversationLoading(false);
            createDataContext.handleChatLoading(false)
        } catch (error) {
            createDataContext.handleConversationLoading(false);
            createDataContext.handleChatLoading(false)
            console.error(error);
        }
    };


    // NOTE: Get active instagram page post info
    const getInstagramPostInfo = async (loadMore) => {
        try {
            createDataContext.handleMoreOptions(false)
            if (loadMore) {
                createDataContext.handleLoadMoreComments(true)
            } else {
                createDataContext.handleChatLoading(true)
            }

            const accountToken = createDataContext.activeAccount?.accessToken
            const postId = createDataContext.activeChat?.postid_or_userid

            if (constant.cancelToken) {
                constant.cancelToken.cancel(); // Cancel the previous request before making a new request
            }

            // Create a new CancelToken
            constant.cancelToken = axios.CancelToken.source();

            if (accountToken !== undefined && postId !== undefined) {
                const response = await axios.get(`https://graph.facebook.com/v17.0/${postId}/comments`, {
                    params: {
                        fields: "from,username,id,text,timestamp,like_count,replies{from,id,text,timestamp,like_count,parent_id}",
                        filter: "stream",
                        access_token: accountToken,
                        order: "reverse_chronological",
                        after: createDataContext.pageCursor.cursor !== "" && loadMore ? createDataContext.pageCursor.cursor : '',
                        limit: 50
                    },
                    cancelToken: constant.cancelToken.token
                });

                const comments = response.data.data.filter((comment) => comment.text !== undefined)
                if (response.data.paging !== undefined) {
                    createDataContext.handlePageCursor({
                        isMore: true,
                        cursor: response.data.paging.cursors.after
                    })
                }


                if (loadMore) {
                    const updatedComments = restructureTheInstagramComment(comments)
                    const moreComments = [...createDataContext.messages, ...updatedComments]
                    if (createDataContext.activeMoreOptionTab === 'Positive Comments' || createDataContext.activeMoreOptionTab === 'Negative Comments') {
                        createDataContext.getSentiments(updatedComments)
                        if (response.data.paging === undefined) {
                            createDataContext.handlePageCursor({
                                isMore: false,
                                cursor: ''
                            })
                        }
                        createDataContext.handleLoadMoreComments(false)
                    } else {
                        createDataContext.handleMessages(moreComments)
                        createDataContext.handleAllMessages(moreComments)
                        if (response.data.paging === undefined) {
                            createDataContext.handlePageCursor({
                                isMore: false,
                                cursor: ''
                            })
                        }
                        createDataContext.handleLoadMoreComments(false)
                    }
                } else {
                    const updatedComments = restructureTheInstagramComment(comments)
                    createDataContext.handleAllMessages(updatedComments)
                    createDataContext.handleMessages(updatedComments)
                    if (response.data.paging === undefined) {
                        createDataContext.handlePageCursor({
                            isMore: false,
                            cursor: ''
                        })
                    }
                    createDataContext.handleChatLoading(false)
                }
            }
        } catch (error) {
            createDataContext.handleLoadMoreComments(false)
            createDataContext.handleChatLoading(false)
            console.log(error)
        }
    }


    // NOTE: Add comment to instagram post
    const addCommentToInstagramPost = async () => {
        try {
            createDataContext.handleSendingMessage(true)
            const accountToken = createDataContext.activeAccount?.accessToken
            const postId = createDataContext.activeChat?.postid_or_userid
            const response = await axios.post(`https://graph.facebook.com/v17.0/${postId}/comments`, {
                access_token: accountToken,
                message: createDataContext.text
            })

            const commentResponse = await axios.get(`https://graph.facebook.com/v17.0/${response.data.id}?fields=from,id,text,timestamp,like_count,replies{from,id,text,timestamp,like_count,parent_id}&access_token=${accountToken}`)
            let newCommentObj = {
                from: {
                    name: commentResponse.data.from.username,
                    id: commentResponse.data.from.id
                },
                id: commentResponse.data.id,
                comment_count: commentResponse.data.replies === undefined ? 0 : commentResponse.data.replies.data.length,
                like_count: commentResponse.data.like_count,
                message: commentResponse.data.text,
                created_time: commentResponse.data.timestamp,
                replies: commentResponse.data.replies === undefined ? [] : commentResponse.data.replies.data.map((reply) => {
                    return {
                        from: {
                            name: reply.from.username,
                            id: reply.from.id,
                        },
                        comment_count: 0,
                        created_time: reply.timestamp,
                        like_count: reply.like_count,
                        id: reply.id,
                        message: reply.text,
                        parent: {
                            id: reply.parent_id
                        }

                    }
                })
            }
            const newArray = [newCommentObj, ...createDataContext.messages]
            const newComment = createDataContext.createCommentTree(newArray)
            createDataContext.handleMessages(newComment)
            createDataContext.handleText("", "text")
            createDataContext.handleSendingMessage(false)
        } catch (error) {
            console.log(error)
            createDataContext.handleText("", "text")
            toast.error('Oops! Something went wrong. Please try again.');
            createDataContext.handleSendingMessage(false)
        }
    }

    // NOTE: Delet instagram page comment
    const deleteInstagramComment = async (comment_id) => {
        try {
            createDataContext.handleDeleting({
                isLoading: true,
                id: comment_id
            })
            const accountToken = createDataContext.activeAccount?.accessToken
            await axios.delete(`https://graph.facebook.com/v17.0/${comment_id}?access_token=${accountToken}`)
            const removedComment = createDataContext.recursivelyRemoveComment(createDataContext.messages, comment_id);
            createDataContext.handleMessages(removedComment)
            createDataContext.handleDeleting({
                isLoading: false,
                id: ""
            })
            toast.success("Comment deleted successfully.")
        } catch (error) {
            console.log(error)
            toast.error('Oops! Something went wrong. Please try again.');
            createDataContext.handleDeleting({
                isLoading: false,
                id: ""
            })
        }
    }

    // NOTE: add reply to instagram comment
    const addInstagramCommentReply = async (id, isMention) => {
        try {
            createDataContext.handleSendingReply(true)
            const workspaceId = localStorage.getItem("organizationId")
            const accountToken = createDataContext.activeAccount?.accessToken
            const postId = createDataContext.activeChat?.postid_or_userid
            const accountId = createDataContext.activeAccount?.socialId
            await axios.post(`https://graph.facebook.com/v17.0/${id}/replies`, {
                access_token: accountToken,
                message: createDataContext.replyText
            })
            if (isMention) {
                const eventResponse = await axios.get(`${constant.socialInboxUrl}inbox/event/single/${workspaceId}/${postId}/${accountId}/instagram/mentions`);
                const comments = await Promise.all(eventResponse.data.event.comments.map(async (data) => {
                    const response = await axios.get(`https://graph.facebook.com/v17.0/${accountId}?access_token=${accountToken}&fields=mentioned_comment.comment_id(${data.id}){username,text,id,timestamp,like_count,replies{username,id,text,timestamp,like_count,parent_id}}`);
                    const comment = response.data.mentioned_comment;
                    return comment
                }));
                const updatedComments = restructureTheInstagramComment(comments);
                createDataContext.handleMessages(updatedComments);
            } else {
                const allCommentResponse = await axios.get(`https://graph.facebook.com/v17.0/${postId}/comments?fields=from,username,id,text,timestamp,like_count,replies{from,id,text,timestamp,like_count,parent_id}&access_token=${accountToken}`)
                const newReply = restructureTheInstagramComment(allCommentResponse.data.data)
                createDataContext.handleMessages(newReply)
            }
            createDataContext.handleSendingReply(false)
            createDataContext.handleReplyText("", "text")
            createDataContext.handleReplyTo("")
        } catch (error) {
            console.log(error)
            toast.error('Oops! Something went wrong. Please try again.');
            createDataContext.handleReplyText("", "text")
            createDataContext.handleSendingReply(false)
            createDataContext.handleReplyTo("")
        }
    }

    // NOTE: get facebook user profile pic
    const getFbUsersProfilePic = async (psid, accountToken) => {
        try {
            const response = await axios.get(`https://graph.facebook.com/v17.0/${psid}?fields=profile_pic&access_token=${accountToken}`)
            return response.data.profile_pic
        } catch (error) {
            console.log(error)
        }
    }


    // NOTE: Instagram Direct Message Code

    // NOTE: Messenger Webhook notification info
    const getMessengerWebhookNotificationInfo = async (data) => {
        try {
            const workspaceId = localStorage.getItem('organizationId')
            if (workspaceId === data.workspaceId) {
                const isUserExists = constant.conversationList.find((person) => person.conversation_id === data.conversationId)

                if (isUserExists !== undefined) {
                    const conversationId = constant.activeChat.conversation_id
                    if (conversationId === data.conversationId) {
                        createDataContext.handleCommentLoading(true)
                        const updatedMessages = [data.message, ...constant.messages]
                        createDataContext.handleNewMessageCount(constant.newMessagesCount + 1)
                        setTimeout(() => {
                            if (!createDataContext.commentLoading) {
                                createDataContext.handleMessages(updatedMessages)
                            }
                            createDataContext.handleCommentLoading(false)
                        }, 1000)
                        const accountId = constant.activeAccount.socialId
                        const unReadResponse = await axios.put(`${constant.socialInboxUrl}inbox/event/update/unread/${workspaceId}/${constant.activeChat.conversation_id}/${accountId}/instagram/messenger`)
                        if (unReadResponse.status === 200) {
                            const updatedConversation = constant.conversationList.map(conversation => {
                                return conversation.conversation_id === constant.activeChat?.conversation_id
                                    ? { ...conversation, unread: 0 }
                                    : conversation;
                            });

                            createDataContext.handleConversationList(updatedConversation);
                        }
                        toast.info('You have new messages.',
                            {
                                position: "bottom-center",
                                autoClose: 2000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                                theme: "light",
                                transition: Bounce, // Use the built-in Bounce transition
                            }
                        );
                    } else {
                        const updatedList = constant.conversationList.map((person) => {
                            if (person.postid_or_userid === data.message.from.id) {
                                return {
                                    ...person,
                                    latest_message: data.message.message,
                                    unread: person.unread + 1
                                };
                            }
                            return person;
                        });
                        const updatedListWithMovedItem = [
                            updatedList.find((messenger) => messenger.postid_or_userid === data.message.from.id),
                            ...updatedList.filter((messenger) => messenger.postid_or_userid !== data.message.from.id)
                        ];

                        createDataContext.handleConversationList(updatedListWithMovedItem);
                    }

                } else {
                    createDataContext.handleWebhookNotificationLoading(true)
                    if (data.newMessage) {
                        const conversationResponse = await axios.get(`https://graph.facebook.com/v17.0/${data.conversationId}?fields=participants&access_token=${constant.activeAccount?.accessToken}&platform=instagram`);
                        const conversation = conversationResponse.data;
                        const profilePic = await getFbUsersProfilePic(conversation.participants.data[1].id, constant.activeAccount?.accessToken);
                        let event = {
                            name_or_caption: conversation.participants.data[1].username,
                            postid_or_userid: conversation.participants.data[1].id,
                            conversation_id: conversation.id,
                            media_url: profilePic,
                            to: conversation.participants.data[0],
                            conversationType: 'messenger',
                            accountType: "instagram"
                        }
                        setTimeout(() => {
                            createDataContext.handleWebhookNotificationLoading(false)
                            if (constant.conversationList.length === 0) {
                                createDataContext.handleActiveChat(event)
                            }
                            let updatedConversationList = [event, ...constant.conversationList];
                            createDataContext.handleConversationList(updatedConversationList)
                        }, 1000)
                    } else {
                        setTimeout(() => {
                            createDataContext.handleWebhookNotificationLoading(false)
                            if (constant.conversationList.length === 0) {
                                createDataContext.handleActiveChat(data.message)
                            }
                            let updatedConversationList = [data.message, ...constant.conversationList];
                            createDataContext.handleConversationList(updatedConversationList)
                        }, 1000)
                    }
                }
            }
        } catch (error) {
            console.log(error)
        }
    }


    // NOTE: get instagram page conversations list
    const getInstagramPageConversations = async (loadMore, isPrivateReply) => {
        try {
            if (loadMore) {
                createDataContext.handleLoadMore(true)
                createDataContext.handleChatLoading(false)
                createDataContext.handleConversationLoading(false);
            } else {
                createDataContext.handleConversationLoading(true);
                createDataContext.handlePaginationInfo(null)
            }
            const accountId = createDataContext.activeAccount?.socialId
            const accountToken = createDataContext.activeAccount?.accessToken
            // const facebookPageId = createDataContext.activeAccount?.facebookPageId
            const workspaceId = localStorage.getItem('organizationId');

            // Fetch event IDs from the socialInbox API
            const eventResponse = await axios.get(`${constant.socialInboxUrl}inbox/event/${workspaceId}/${accountId}/instagram/messenger?page=${constant.paginationInfo?.page || 1}&limit=${constant.paginationInfo?.limit || 10}`);
            const eventIds = eventResponse.data.events.map(data => data.eventId);

            // Fetch events details in parallel using Promise.all
            const pageConversationsPromises = eventIds.map(async (conversationId) => {
                try {
                    const response = await axios.get(`https://graph.facebook.com/v17.0/${conversationId}?fields=participants&platform=instagram&access_token=${accountToken}`);
                    return response.data;
                } catch (error) {
                    console.error(`Error fetching data for conversationId ${conversationId}:`, error);
                    return null;
                }
            });

            const conversations = await Promise.all(pageConversationsPromises).then(data => data.filter(Boolean));;
            const updatedMessengerConversation = await Promise.all(conversations.map(async (conversation, index) => {
                const registeredConversation = eventResponse.data.events.find(data => data.eventId === conversation.id);

                if (!registeredConversation) return null; // Return early if registeredConversation is not found

                try {
                    const [profileResponse] = await Promise.all([
                        axios.get(`https://graph.facebook.com/v17.0/${conversation.participants.data[1].id}?access_token=${accountToken}`)
                            .catch(error => {
                                console.error('Error fetching profile data:', error);
                                return { data: { profile_pic: "" } };
                            })
                    ]);

                    const profileData = profileResponse.data;

                    return {
                        name_or_caption: conversation.participants.data[1].username,
                        postid_or_userid: conversation.participants.data[1].id,
                        conversation_id: conversation.id,
                        media_url: profileData?.profile_pic || "",
                        conversationType: 'messenger',
                        accountType: "instagram",
                        unread: index === 0 ? 0 : registeredConversation.unread,
                        isMessageWindowActive: registeredConversation?.isMessageWindowActive !== undefined ? registeredConversation?.isMessageWindowActive : false
                    };
                } catch (error) {
                    console.error('Error fetching profile data:', error);
                    return null;
                }
            }));

            // Filter out null values (conversations without registeredConversation)
            const filteredConversations = updatedMessengerConversation.filter(conversation => conversation !== null);

            if (filteredConversations.length === 0) {
                createDataContext.handleActiveChat({})
                createDataContext.handleConversationList([])
                createDataContext.handleMessages([])
            } else {
                if (filteredConversations[0].unread > 0) {
                    const response = await axios.put(`${constant.socialInboxUrl}inbox/event/update/unread/${workspaceId}/${filteredConversations[0].conversation_id}/${accountId}/instagram/messenger`)
                    if (response.status === 200) {
                        let obj = {
                            ...filteredConversations[0],
                            unread: 0
                        }
                        createDataContext.handleActiveChat(obj);
                    }
                } else {
                    if (!loadMore) {
                        if (!isPrivateReply) {
                            createDataContext.handleActiveChat(filteredConversations[0]);
                        }
                    }
                }

                eventIds.forEach((data) => {
                    socket.emit('create', data);
                });
            }

            createDataContext.handleConversationList([...constant.conversationList, ...filteredConversations])
            createDataContext.handleAllConversations([...constant.allConversationList, ...filteredConversations])

            createDataContext.handlePaginationInfo({
                ...eventResponse.data.pagination,
                page: Number(eventResponse.data.pagination.page) + 1
            })

            createDataContext.handleConversationLoading(false)
            createDataContext.handleChatLoading(false)
            createDataContext.handleLoadMore(false)
        } catch (error) {
            createDataContext.handleConversationLoading(false)
            createDataContext.handleChatLoading(false)
            console.log(error)
        }
    }



    // NOTE: Get all messages of facebook page messenger
    const getInstagramPageMessages = async (loadMore) => {
        try {
            createDataContext.handleMoreOptions(false)
            if (loadMore) {
                createDataContext.handleLoadMoreComments(true)
            } else {
                createDataContext.handleChatLoading(true)
            }
            const accountToken = createDataContext.activeAccount?.accessToken
            const conversationId = createDataContext.activeChat?.conversation_id


            if (constant.cancelToken) {
                constant.cancelToken.cancel(); // Cancel the previous request before making a new request
            }

            // Create a new CancelToken
            constant.cancelToken = axios.CancelToken.source();

            if (accountToken !== undefined && conversationId !== undefined) {
                const response = await axios.get(`https://graph.facebook.com/v17.0/${conversationId}/messages`, {
                    params: {
                        fields: 'message,to,from,created_time,attachments,story,reactions',
                        access_token: accountToken,
                        after: createDataContext.pageCursor.cursor !== "" && loadMore ? createDataContext.pageCursor.cursor : ''
                    },
                    cancelToken: constant.cancelToken.token
                })
                const filteredMessages = response.data.data.filter(({ attachments, message, story }) => {
                    return (attachments !== undefined && message === "") || (attachments === undefined && message !== "") || (story !== undefined && message === "");
                });

                if (response.data.paging !== undefined) {
                    createDataContext.handlePageCursor({
                        isMore: true,
                        cursor: response.data.paging.cursors.after
                    })
                }

                if (loadMore) {
                    const messages = [...createDataContext.messages, ...filteredMessages]
                    createDataContext.handleMessages(messages)
                    if (response.data.paging === undefined) {
                        createDataContext.handlePageCursor({
                            isMore: false,
                            cursor: ''
                        })
                    }
                    createDataContext.handleLoadMoreComments(false)
                } else {
                    createDataContext.handleMessages(filteredMessages)
                    if (response.data.paging === undefined) {
                        createDataContext.handlePageCursor({
                            isMore: false,
                            cursor: ''
                        })
                    }
                    createDataContext.handleChatLoading(false)
                }
            }
        } catch (error) {
            createDataContext.handleChatLoading(false)
            createDataContext.handleLoadMoreComments(false)
            console.log(error)
        }
    }


    // NOTE: Send message to user using instagram page
    const sendMessageToUserInstagramDm = async () => {
        try {
            createDataContext.handleSendingMessage(true)
            const accountToken = createDataContext.activeAccount?.accessToken
            const facebookPageId = createDataContext.activeAccount?.facebookPageId
            const recipient_id = createDataContext.activeChat?.postid_or_userid
            if (createDataContext.messageMedia.length > 0) {
                let messageIdArray = []
                const response = await axios.post(`https://graph.facebook.com/v17.0/${facebookPageId}/messages?platform=instagram`, {
                    recipient: { id: recipient_id },
                    message: {
                        attachment: {
                            type: createDataContext.messageMedia[0].type,
                            payload: {
                                url: createDataContext.messageMedia[0].URL,
                                is_reusable: true
                            },

                        }
                    },
                    access_token: accountToken,
                    tag: 'HUMAN_AGENT'
                })

                messageIdArray.push(response.data.message_id)
                await updateMessages(messageIdArray)
            } else {
                const response = await axios.post(`https://graph.facebook.com/v17.0/${facebookPageId}/messages?platform=instagram`, {
                    recipient: { id: recipient_id },
                    message: { text: createDataContext.text },
                    access_token: accountToken,
                    tag: 'HUMAN_AGENT'
                })
                await updateMessages([response.data.message_id])
            }

            if (!createDataContext.activeChat?.isMessageWindowActive) {
                await axios.put(`${constant.socialInboxUrl}inbox/event/update/message/window/${createDataContext.activeChat?.conversation_id}/${createDataContext.activeAccount?.socialId}/instagram/messenger`)
                createDataContext.handleActiveChat({
                    ...createDataContext.activeChat,
                    isMessageWindowActive: true
                })
            }

            createDataContext.handleSendingMessage(false)
            createDataContext.handleText("", "text")
            createDataContext.handleMessageMedia([])
        } catch (error) {
            console.log(error)
            createDataContext.handleText("", "text")
            toast.error('Oops! Something went wrong. Please try again.');
            createDataContext.handleSendingMessage(false)
        }

    }


    // NOTE: update the message to messages array by getting whole message info from instagram
    const updateMessages = async (messageIds) => {
        try {
            const accountToken = createDataContext.activeAccount?.accessToken;
            for await (const messageId of messageIds) {
                const messageInfo = await axios.get(`https://graph.facebook.com/v17.0/${messageId}?fields=id,created_time,from,to,message,attachments&access_token=${accountToken}`);
                const newMessage = [messageInfo.data, ...createDataContext.messages]
                createDataContext.handleMessages(newMessage)
            }
        } catch (error) {
            console.log(error);
        }
    };


    // NOTE: Mentions
    const getAllInstagramPageMentionsEvents = async (loadMore) => {
        try {
            if (loadMore) {
                createDataContext.handleLoadMore(true)
                createDataContext.handleChatLoading(false)
                createDataContext.handleConversationLoading(false);
            } else {
                createDataContext.handleConversationLoading(true);
                createDataContext.handlePaginationInfo(null)
            }

            const accountId = createDataContext.activeAccount?.socialId;
            const accountToken = createDataContext.activeAccount?.accessToken;
            const workspaceId = localStorage.getItem('organizationId');

            // Fetch event IDs from the socialInbox API
            const eventResponse = await axios.get(`${constant.socialInboxUrl}inbox/event/${workspaceId}/${accountId}/instagram/mentions?page=${constant.paginationInfo?.page || 1}&limit=${constant.paginationInfo?.limit || 10}`);
            const eventInfo = eventResponse.data.events.map((data) => {
                let obj = {
                    id: data.eventId,
                    isMentionInCaption: data.isMentionInCaption,
                }
                if (!data.isMentionInCaption) {
                    obj.comment_id = data.comments[0]?.id
                }

                return obj
            })

            const pagePostsPromises = eventInfo.map(async (data) => {
                try {
                    let commentResponse;
                    if (data.isMentionInCaption) {
                        commentResponse = await axios.get(`https://graph.facebook.com/v17.0/${accountId}?access_token=${accountToken}&fields=mentioned_media.media_id(${data.id}){permalink,caption,comments_count,id,like_count,media_type,media_url,timestamp,username,thumbnail_url}`)
                        let obj = {
                            postId: commentResponse.data.mentioned_media.id,
                            media: {
                                id: commentResponse.data.mentioned_media.id,
                                caption: commentResponse.data.mentioned_media.caption,
                                media_url: commentResponse.data.mentioned_media.thumbnail_url ? commentResponse.data.mentioned_media.thumbnail_url : commentResponse.data.mentioned_media.media_url,
                                like_count: commentResponse.data.mentioned_media.like_count,
                                comments_count: commentResponse.data.mentioned_media.comments_count,
                                timestamp: commentResponse.data.mentioned_media.timestamp,
                                username: commentResponse.data.mentioned_media.username
                            },
                            timestamp: commentResponse.data.mentioned_media.timestamp,
                            isMentionInCaption: true,
                            postLink: commentResponse.data.mentioned_media.permalink
                        }

                        return obj
                    } else {
                        commentResponse = await axios.get(`https://graph.facebook.com/v17.0/${accountId}?access_token=${accountToken}&fields=mentioned_comment.comment_id(${data.comment_id}){id,timestamp,media{permalink,thumbnail_url,id,timestamp,media_url,like_count,comments_count,caption,username}}`);
                        let obj = {
                            postId: commentResponse.data.mentioned_comment.media.id,
                            comment_id: commentResponse.data.mentioned_comment.id,
                            media: commentResponse.data.mentioned_comment.media,
                            timestamp: commentResponse.data.mentioned_comment.timestamp,
                            isMentionInCaption: false,
                            postLink: commentResponse.data.mentioned_comment.media.permalink
                        }
                        return obj;
                    }
                } catch (error) {
                    console.error(`Error fetching data`, error.response.data.error.message);
                    return null;
                }
            });

            // Wait for all promises to complete
            const pagePostsData = await Promise.all(pagePostsPromises);

            // Filter out the results that are null (requests that failed)
            const pagePosts = pagePostsData.filter(data => data !== null);

            const updatedPagePosts = pagePosts.map((post, index) => {
                const registeredPost = eventResponse.data.events.find((data) => data.eventId === post.postId);
                if (!registeredPost) return null; // Return early if registeredPost is not found
                return {
                    name_or_caption: post.media.caption === undefined ? "No Caption" : post.media.caption,
                    postid_or_userid: post.postId,
                    likes: post.media.like_count,
                    comments: post.media.comments_count,
                    media_url: post.media.thumbnail_url === undefined ? post.media.media_url : post.media.thumbnail_url,
                    created_time: post.media.timestamp,
                    attachments: post.children === undefined ? [{
                        media_type: "image",
                        media_url: post.media.thumbnail_url ? post.media.thumbnail_url : post.media.media_url,
                        id: ""
                    }] : post.children.data.map((data) => {
                        return {
                            media_type: data.media_type === "IMAGE" ? "image" : "video",
                            media_url: data.media_url,
                            id: data.id
                        }
                    }),
                    conversationType: 'mentions',
                    accountType: "instagram",
                    unread: index === 0 ? 0 : registeredPost.unread,
                    owner: post.media.username,
                    isMentionInCaption: post.isMentionInCaption,
                    postLink: post.postLink,
                    isPostExists: true
                }

            }).filter(Boolean); // Remove null values from the resulting array
            if (eventResponse.data.events.length === 0) {
                createDataContext.handleConversationList([])
                createDataContext.handleAllConversations([])
            } else {
                if (updatedPagePosts[0].unread > 0) {
                    const response = await axios.put(`${constant.socialInboxUrl}inbox/event/update/unread/${workspaceId}/${updatedPagePosts[0].postid_or_userid}/${accountId}/instagram/mentions`)
                    if (response.status === 200) {
                        let obj = {
                            ...updatedPagePosts[0],
                            unread: 0
                        }
                        createDataContext.handleActiveChat(obj);
                    }
                } else {
                    if (!loadMore) {
                        createDataContext.handleActiveChat(updatedPagePosts[0]);
                    }
                }
            }

            createDataContext.handleConversationList([...constant.conversationList, ...updatedPagePosts]);
            createDataContext.handleAllConversations([...constant.allConversationList, ...updatedPagePosts]);
            createDataContext.handlePaginationInfo({
                ...eventResponse.data.pagination,
                page: Number(eventResponse.data.pagination.page) + 1
            })
            eventInfo.forEach((data) => {
                socket.emit('create', data.comment_id);
            });
            createDataContext.handleLoadMore(false)
            createDataContext.handleConversationLoading(false);
            createDataContext.handleChatLoading(false)
        } catch (error) {
            createDataContext.handleConversationLoading(false);
            createDataContext.handleChatLoading(false)
            console.error(error);
        }
    };



    const getUsersTaggedPost = async (loadMore) => {
        try {
            if (loadMore) {
                createDataContext.handleLoadMore(true)
                createDataContext.handleChatLoading(false)
                createDataContext.handleConversationLoading(false);
            } else {
                createDataContext.handleConversationLoading(true);
                createDataContext.handlePaginationInfo(null)
            }

            const accountId = createDataContext.activeAccount?.socialId;
            const accountToken = createDataContext.activeAccount?.accessToken;
            const response = await axios.get(`https://graph.facebook.com/v17.0/${accountId}/tags?access_token=${accountToken}&fields=permalink,username,media_url,caption,thumbnail_url,timestamp,comments_count,like_count,comments,children{media_type,media_url,thumbnail_url}`)
            const updatedTaggedData = response.data.data.map((post) => {
                let obj = {
                    name_or_caption: post.caption === undefined ? "No Caption" : post.caption,
                    postid_or_userid: post.id,
                    likes: post.like_count,
                    comments: post.comments_count,
                    media_url: post.thumbnail_url === undefined ? post.media_url : post.thumbnail_url,
                    created_time: post.timestamp,
                    attachments: post.children === undefined ? [{
                        media_type: "image",
                        media_url: post.thumbnail_url ? post.thumbnail_url : post.media_url,
                        id: ""
                    }] : post.children.data.map((data) => {
                        return {
                            media_type: data.media_type === "IMAGE" ? "image" : "video",
                            media_url: data.media_url,
                            id: data.id
                        }
                    }),
                    conversationType: 'tags',
                    accountType: "instagram",
                    unread: 0,
                    owner: post.username,
                    postLink: post.permalink,
                    isPostExists: true
                }

                return obj
            })
            createDataContext.handleActiveChat(updatedTaggedData[0])
            createDataContext.handleConversationList(updatedTaggedData)
            createDataContext.handleAllConversations(updatedTaggedData)
            createDataContext.handleLoadMore(false)
            createDataContext.handleConversationLoading(false);
            createDataContext.handleChatLoading(false)

        } catch (error) {
            createDataContext.handleConversationLoading(false);
            createDataContext.handleChatLoading(false)
            console.error(error);
        }
    }


    const getPostsMentionData = async () => {
        try {
            createDataContext.handleMoreOptions(false)
            createDataContext.handleChatLoading(true)
            const accountId = createDataContext.activeAccount?.socialId
            const accountToken = createDataContext.activeAccount?.accessToken
            const postId = createDataContext.activeChat?.postid_or_userid
            const isMentionInCaption = createDataContext.activeChat?.isMentionInCaption
            const workspaceId = localStorage.getItem('organizationId')

            if (constant.cancelToken) {
                constant.cancelToken.cancel(); // Cancel the previous request before making a new request
            }

            // Create a new CancelToken
            constant.cancelToken = axios.CancelToken.source();

            if (isMentionInCaption) {
                const response = await axios.get(`https://graph.facebook.com/v17.0/${accountId}?access_token=${accountToken}&fields=mentioned_media.media_id(${postId}){comments{from,username,id,text,timestamp,like_count,replies{from,id,text,timestamp,like_count,parent_id}}}`, {
                    cancelToken: constant.cancelToken.token
                });
                if (response.data.mentioned_media.comments) {
                    const updatedComments = restructureTheInstagramComment(response.data.mentioned_media.comments.data)
                    createDataContext.handleMessages(updatedComments)
                } else {
                    createDataContext.handleMessages([])
                }

            } else {
                const eventResponse = await axios.get(`${constant.socialInboxUrl}inbox/event/single/${workspaceId}/${postId}/${accountId}/instagram/mentions`, {
                    cancelToken: constant.cancelToken.token
                });
                const comments = await Promise.all(eventResponse.data.event.comments.map(async (data) => {
                    const response = await axios.get(`https://graph.facebook.com/v17.0/${accountId}?access_token=${accountToken}&fields=mentioned_comment.comment_id(${data.id}){username,text,id,timestamp,like_count}`, {
                        cancelToken: constant.cancelToken.token
                    });
                    const comment = response.data.mentioned_comment;
                    return comment
                }));
                const updatedComments = restructureTheInstagramComment(comments);
                createDataContext.handleMessages(updatedComments);
            }
            createDataContext.handleChatLoading(false)
        } catch (error) {
            createDataContext.handleMessages([]);
            createDataContext.handleConversationLoading(false);
            createDataContext.handleChatLoading(false)
            console.error(error);
        }
    }


    const replyToMentionPostComment = async (id) => {
        try {
            createDataContext.handleSendingReply(true)
            const accountId = createDataContext.activeAccount?.socialId
            const accountToken = createDataContext.activeAccount?.accessToken
            const postId = createDataContext.activeChat?.postid_or_userid
            await axios.post(`https://graph.facebook.com/v17.0/${accountId}/mentions`, {
                media_id: postId,
                comment_id: id,
                message: createDataContext.replyText,
                access_token: accountToken
            })

            const response = await axios.get(`https://graph.facebook.com/v17.0/${accountId}?access_token=${accountToken}&fields=mentioned_media.media_id(${postId}){comments{from,username,id,text,timestamp,like_count,replies{from,id,text,timestamp,like_count,parent_id}}}`);
            if (response.data.mentioned_media.comments) {
                const updatedComments = restructureTheInstagramComment(response.data.mentioned_media.comments.data)
                createDataContext.handleMessages(updatedComments)
            } else {
                createDataContext.handleMessages([])
            }

            createDataContext.handleSendingReply(false)
            createDataContext.handleReplyText("", "text")
            createDataContext.handleReplyTo("")
        } catch (error) {
            createDataContext.handleSendingReply(false)
            console.log(error)
        }
    }

    const reactToInstaMessage = async (accountId, messageId, reaction) => {
        try {
            createDataContext.handleReactionLoading(true)
            const facebookPageId = createDataContext.activeAccount?.facebookPageId
            const accessToken = createDataContext.activeAccount?.accessToken
            await axios.post(`https://graph.facebook.com/v17.0/${facebookPageId}/messages?platform=instagram`, {
                access_token: accessToken,
                recipient: {
                    id: accountId
                },
                sender_action: 'react',
                payload: {
                    message_id: messageId,
                    reaction: reaction
                }
            })

            const updatedMessages = createDataContext.messages.map((message) => {
                return message.id === messageId ? {
                    ...message,
                    reactions: { data: [{ reaction: '❤' }] }
                } : message;
            });

            createDataContext.handleMessages(updatedMessages)
            createDataContext.handleReactionLoading(false)
        } catch (error) {
            console.log(error)
            createDataContext.handleReactionLoading(false)
        }
    }


    const context = {

        // Functions
        // getInstagramPosts: getInstagramPosts,
        getInstagramPostInfo: getInstagramPostInfo,
        addCommentToInstagramPost: addCommentToInstagramPost,
        restructureTheInstagramComment: restructureTheInstagramComment,
        deleteInstagramComment: deleteInstagramComment,
        addInstagramCommentReply: addInstagramCommentReply,
        getAllInstagramPageEvents: getAllInstagramPageEvents,
        getInstagramPageConversations: getInstagramPageConversations,
        getInstagramPageMessages: getInstagramPageMessages,
        sendMessageToUserInstagramDm: sendMessageToUserInstagramDm,
        getAllInstagramPageMentionsEvents: getAllInstagramPageMentionsEvents,
        getUsersTaggedPost: getUsersTaggedPost,
        getPostsMentionData: getPostsMentionData,
        replyToMentionPostComment: replyToMentionPostComment,
        reactToInstaMessage: reactToInstaMessage
    }

    return (
        <InstagramContext.Provider value={context}>{props.children}</InstagramContext.Provider>
    )
}


export default InstagramContext